import moment from "moment";
import Navbar from "../navbar/navbar";
import React, { useState, useEffect } from "react";
const Create_new_permission = () => {
  const currentDate = moment().format("MMMM Do YYYY");
  const [currentTime, setCurrentTime] = useState(moment().format("h:mm:ss a"));
  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(moment().format("h:mm:ss a"));
    }, 1000);
    return () => clearInterval(timer);
  }, []);
  return (
    <div className="mx-10 mt-5">
      <Navbar />
      <div className="flex justify-between items-center mt-6">
        <div>
          <p className="text-[30px] font-semibold">Create User Permissions </p>
          <p className="text-[15px] font-semibold text-[#6A2E92]">Permission</p>
        </div>
        <div className="flex gap-6 items-center">
          <div>
            <div className="text-[#3D0B5E] text-[20px] font-bold">
              {currentDate}
            </div>
          </div>
          <div className="text-[20px] font-bold">{currentTime}</div>
        </div>
      </div>
      <div className="flex gap-6 items-center mt-6">
        <div>
          <label className="block text-sm font-medium text-gray-700 ">
            Role:
          </label>
        </div>
        <div>
          {" "}
          <input
            type="text"
            className="mt-1 block  px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:ring-purple-500"
          />
        </div>
        <div>
          {" "}
          <label className="block text-sm font-medium text-gray-700">
            Role Description:
          </label>
        </div>
        <div>
          {" "}
          <input
            type="text"
            className="mt-1 block px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:ring-purple-500"
          />
        </div>
      </div>
      {/* //grid section */}
      <div className="grid grid-cols-4 gap-3 mt-8 text-center">
        <div>
          <div className="border border-shadow bg-gray-50 w-21 rounded-md p-4">
            Employee Information Management
          </div>
          <div className="flex items-center mt-8 text-purple-600">
            <input type="checkbox" className="mr-2" />
            <label className="block text-sm font-medium ">Dashboard:</label>
          </div>
          <div className="flex items-center gap-4">
            <div className="flex items-center mt-8 text-black">
              <input type="checkbox" className="mr-2" />
              <label className="block text-sm font-medium ">View</label>
            </div>
          </div>

          <div className="flex items-center mt-8 text-black">
            {/* <input type="checkbox" className="mr-2" /> */}
            <label className="block text-sm font-medium ">
              Employee Quick Onboard:
            </label>
          </div>
          <div className="flex items-center gap-4">
            <div className="flex items-center mt-8 text-black">
              <input type="checkbox" className="mr-2" />
              <label className="block text-sm font-medium ">Create</label>
            </div>
            <div className="flex items-center mt-8 text-black">
              <input type="checkbox" className="mr-2" />
              <label className="block text-sm font-medium ">
                <> view</>
              </label>
            </div>
          </div>
          <div className="flex items-center gap-4">
            <div className="flex items-center mt-8 text-black">
              <input type="checkbox" className="mr-2" />
              <label className="block text-sm font-medium ">update</label>
            </div>
            <div className="flex items-center mt-8 text-black">
              <input type="checkbox" className="mr-2" />
              <label className="block text-sm font-medium ">
                <> delete</>
              </label>
            </div>
          </div>

          <div className="flex items-center mt-8 text-black">
            {/* <input type="checkbox" className="mr-2" /> */}
            <label className="block text-sm font-medium ">
              View Employee details:
            </label>
          </div>
          <div className="flex items-center gap-4">
            <div className="flex items-center mt-8 text-black">
              <input type="checkbox" className="mr-2" />
              <label className="block text-sm font-medium ">view</label>
            </div>
            <div className="flex items-center mt-8 text-black">
              <input type="checkbox" className="mr-2" />
              <label className="block text-sm font-medium ">
                <> edit</>
              </label>
            </div>
          </div>
          <div className="flex items-center gap-4">
            <div className="flex items-center mt-8 text-black">
              <input type="checkbox" className="mr-2" />
              <label className="block text-sm font-medium ">delete</label>
            </div>
          </div>

          <div className="flex items-center mt-8 text-black">
            {/* <input type="checkbox" className="mr-2" /> */}
            <label className="block text-sm font-medium ">
              History Login details:
            </label>
          </div>
          <div className="flex items-center gap-4">
            <div className="flex items-center mt-8 text-black">
              <input type="checkbox" className="mr-2" />
              <label className="block text-sm font-medium ">view</label>
            </div>
          </div>

          <div className="flex items-center mt-8 text-black">
            {/* <input type="checkbox" className="mr-2" /> */}
            <label className="block text-sm font-medium ">
              User Account Creation:
            </label>
          </div>
          <div className="flex items-center gap-4">
            <div className="flex items-center mt-8 text-black">
              <input type="checkbox" className="mr-2" />
              <label className="block text-sm font-medium ">Create</label>
            </div>
            <div className="flex items-center mt-8 text-black">
              <input type="checkbox" className="mr-2" />
              <label className="block text-sm font-medium ">
                <> Edit</>
              </label>
            </div>
          </div>
          <div className="flex items-center gap-4">
            <div className="flex items-center mt-8 text-black">
              <input type="checkbox" className="mr-2" />
              <label className="block text-sm font-medium ">Update</label>
            </div>
            <div className="flex items-center mt-8 text-black">
              <input type="checkbox" className="mr-2" />
              <label className="block text-sm font-medium ">
                <> Delete</>
              </label>
            </div>
          </div>
        </div>
        <div>
          <div className="border border-shadow bg-gray-50 w-21 rounded-md p-4">
            Time & Attendance
          </div>
          <div className="flex items-center mt-8 text-purple-600">
            <input type="checkbox" className="mr-2" />
            <label className="block text-sm font-medium ">
              Live Dashboard:
            </label>
          </div>

          <div className="flex items-center mt-8 text-black">
            <input type="checkbox" className="mr-2" />
            <label className="block text-sm font-medium ">View</label>
          </div>

          <div className="flex items-center mt-8 text-purple-600">
            <input type="checkbox" className="mr-2" />
            <label className="block text-sm font-medium ">
              Time Management:
            </label>
          </div>

          <div className="flex items-center mt-8 text-black">
            {/* <input type="checkbox" className="mr-2" /> */}
            <label className="block text-sm font-medium ">
              Create Timetable:
            </label>
          </div>
          <div className="flex items-center gap-4">
            <div className="flex items-center mt-8 text-black">
              <input type="checkbox" className="mr-2" />
              <label className="block text-sm font-medium ">Create</label>
            </div>
            <div className="flex items-center mt-8 text-black">
              <input type="checkbox" className="mr-2" />
              <label className="block text-sm font-medium ">
                <> edit</>
              </label>
            </div>
          </div>
          <div className="flex items-center gap-4">
            <div className="flex items-center mt-8 text-black">
              <input type="checkbox" className="mr-2" />
              <label className="block text-sm font-medium ">view</label>
            </div>
            <div className="flex items-center mt-8 text-black">
              <input type="checkbox" className="mr-2" />
              <label className="block text-sm font-medium ">
                <> delete</>
              </label>
            </div>
          </div>

          <div className="flex items-center mt-8 text-black">
            {/* <input type="checkbox" className="mr-2" /> */}
            <label className="block text-sm font-medium ">
              View and Edit Timetable:
            </label>
          </div>
          <div className="flex items-center gap-4">
            <div className="flex items-center mt-8 text-black">
              <input type="checkbox" className="mr-2" />
              <label className="block text-sm font-medium ">Create</label>
            </div>
            <div className="flex items-center mt-8 text-black">
              <input type="checkbox" className="mr-2" />
              <label className="block text-sm font-medium ">
                <> View</>
              </label>
            </div>
          </div>
          <div className="flex items-center gap-4">
            <div className="flex items-center mt-8 text-black">
              <input type="checkbox" className="mr-2" />
              <label className="block text-sm font-medium ">update</label>
            </div>
            <div className="flex items-center mt-8 text-black">
              <input type="checkbox" className="mr-2" />
              <label className="block text-sm font-medium ">
                <> delete</>
              </label>
            </div>
          </div>

          <div className="flex items-center mt-8 text-purple-600">
            <input type="checkbox" className="mr-2" />
            <label className="block text-sm font-medium ">Reports:</label>
          </div>

          <div className="flex items-center mt-8 text-black">
            {/* <input type="checkbox" className="mr-2" /> */}
            <label className="block text-sm font-medium ">
              Check in - Check out Report:
            </label>
          </div>
          <div className="flex items-center gap-4">
            <div className="flex items-center mt-8 text-black">
              <input type="checkbox" className="mr-2" />
              <label className="block text-sm font-medium ">view</label>
            </div>
            <div className="flex items-center mt-8 text-black">
              <input type="checkbox" className="mr-2" />
              <label className="block text-sm font-medium ">
                <> add</>
              </label>
            </div>
          </div>
          <div className="flex items-center gap-4">
            <div className="flex items-center mt-8 text-black">
              <input type="checkbox" className="mr-2" />
              <label className="block text-sm font-medium ">edit</label>
            </div>
            <div className="flex items-center mt-8 text-black">
              <input type="checkbox" className="mr-2" />
              <label className="block text-sm font-medium ">
                <> delete</>
              </label>
            </div>
          </div>

          <div className="flex items-center mt-8 text-black">
            {/* <input type="checkbox" className="mr-2" /> */}
            <label className="block text-sm font-medium ">
              Absence Report:
            </label>
          </div>
          <div className="flex items-center gap-4">
            <div className="flex items-center mt-8 text-black">
              <input type="checkbox" className="mr-2" />
              <label className="block text-sm font-medium ">view</label>
            </div>
            <div className="flex items-center mt-8 text-black">
              <input type="checkbox" className="mr-2" />
              <label className="block text-sm font-medium ">
                <> add</>
              </label>
            </div>
          </div>
          <div className="flex items-center gap-4">
            <div className="flex items-center mt-8 text-black">
              <input type="checkbox" className="mr-2" />
              <label className="block text-sm font-medium ">edit</label>
            </div>
            <div className="flex items-center mt-8 text-black">
              <input type="checkbox" className="mr-2" />
              <label className="block text-sm font-medium ">
                <> delete</>
              </label>
            </div>
          </div>

          <div className="flex items-center mt-8 text-black">
            {/* <input type="checkbox" className="mr-2" /> */}
            <label className="block text-sm font-medium ">
              Summary Report:
            </label>
          </div>
          <div className="flex items-center gap-4">
            <div className="flex items-center mt-8 text-black">
              <input type="checkbox" className="mr-2" />
              <label className="block text-sm font-medium ">view</label>
            </div>
            <div className="flex items-center mt-8 text-black">
              <input type="checkbox" className="mr-2" />
              <label className="block text-sm font-medium ">
                <> add</>
              </label>
            </div>
          </div>
          <div className="flex items-center gap-4">
            <div className="flex items-center mt-8 text-black">
              <input type="checkbox" className="mr-2" />
              <label className="block text-sm font-medium ">edit</label>
            </div>
            <div className="flex items-center mt-8 text-black">
              <input type="checkbox" className="mr-2" />
              <label className="block text-sm font-medium ">
                <> delete</>
              </label>
            </div>
          </div>

          <div className="flex items-center mt-8 text-black">
            {/* <input type="checkbox" className="mr-2" /> */}
            <label className="block text-sm font-medium ">
              Departmental Comparison:
            </label>
          </div>
          <div className="flex items-center gap-4">
            <div className="flex items-center mt-8 text-black">
              <input type="checkbox" className="mr-2" />
              <label className="block text-sm font-medium ">view</label>
            </div>
            <div className="flex items-center mt-8 text-black">
              <input type="checkbox" className="mr-2" />
              <label className="block text-sm font-medium ">
                <> add</>
              </label>
            </div>
          </div>
          <div className="flex items-center gap-4">
            <div className="flex items-center mt-8 text-black">
              <input type="checkbox" className="mr-2" />
              <label className="block text-sm font-medium ">edit</label>
            </div>
            <div className="flex items-center mt-8 text-black">
              <input type="checkbox" className="mr-2" />
              <label className="block text-sm font-medium ">
                <> delete</>
              </label>
            </div>
          </div>

          <div className="flex items-center mt-8 text-black">
            {/* <input type="checkbox" className="mr-2" /> */}
            <label className="block text-sm font-medium ">
              Employee Attendance History
            </label>
          </div>
          <div className="flex items-center gap-4">
            <div className="flex items-center mt-8 text-black">
              <input type="checkbox" className="mr-2" />
              <label className="block text-sm font-medium ">Create</label>
            </div>
            <div className="flex items-center mt-8 text-black">
              <input type="checkbox" className="mr-2" />
              <label className="block text-sm font-medium ">
                <> view</>
              </label>
            </div>
          </div>
          <div className="flex items-center gap-4">
            <div className="flex items-center mt-8 text-black">
              <input type="checkbox" className="mr-2" />
              <label className="block text-sm font-medium ">update</label>
            </div>
            <div className="flex items-center mt-8 text-black">
              <input type="checkbox" className="mr-2" />
              <label className="block text-sm font-medium ">
                <> delete</>
              </label>
            </div>
          </div>
        </div>
        <div>
          <div className="border border-shadow bg-gray-50 w-21 rounded-md p-4">
            Leave Management
          </div>
          <div className="flex items-center mt-8 text-purple-600">
            <input type="checkbox" className="mr-2" />
            <label className="block text-sm font-medium ">Dashboard</label>
          </div>

          <div className="flex items-center mt-8 text-black">
            <input type="checkbox" className="mr-2" />
            <label className="block text-sm font-medium ">View</label>
          </div>

          <div className="flex items-center mt-8 text-purple-600">
            <input type="checkbox" className="mr-2" />
            <label className="block text-sm font-medium ">
              Employee Leave Management
            </label>
          </div>

          <div className="flex items-center mt-8 text-black">
            {/* <input type="checkbox" className="mr-2" /> */}
            <label className="block text-sm font-medium ">
              Leave Quotas Management
            </label>
          </div>

          <div className="flex items-center gap-4">
            <div className="flex items-center mt-8 text-black">
              <input type="checkbox" className="mr-2" />
              <label className="block text-sm font-medium ">Add</label>
            </div>
            <div className="flex items-center mt-8 text-black">
              <input type="checkbox" className="mr-2" />
              <label className="block text-sm font-medium ">
                <> View</>
              </label>
            </div>
          </div>
          <div className="flex items-center gap-4">
            <div className="flex items-center mt-8 text-black">
              <input type="checkbox" className="mr-2" />
              <label className="block text-sm font-medium ">Edit</label>
            </div>
            <div className="flex items-center mt-8 text-black">
              <input type="checkbox" className="mr-2" />
              <label className="block text-sm font-medium ">
                <> Delete</>
              </label>
            </div>
          </div>

          <div className="flex items-center mt-8 text-purple-600">
            <input type="checkbox" className="mr-2" />
            <label className="block text-sm font-medium ">
              Leave Approval Process
            </label>
          </div>

          <div className="flex items-center mt-8 text-black">
            {/* <input type="checkbox" className="mr-2" /> */}
            <label className="block text-sm font-medium ">Leave Request</label>
          </div>
          <div className="flex items-center gap-4">
            <div className="flex items-center mt-8 text-black">
              <input type="checkbox" className="mr-2" />
              <label className="block text-sm font-medium ">Add</label>
            </div>
            <div className="flex items-center mt-8 text-black">
              <input type="checkbox" className="mr-2" />
              <label className="block text-sm font-medium ">
                <> View</>
              </label>
            </div>
          </div>
          <div className="flex items-center gap-4">
            <div className="flex items-center mt-8 text-black">
              <input type="checkbox" className="mr-2" />
              <label className="block text-sm font-medium ">Edit</label>
            </div>
            <div className="flex items-center mt-8 text-black">
              <input type="checkbox" className="mr-2" />
              <label className="block text-sm font-medium ">
                <> Delete</>
              </label>
            </div>
          </div>

          <div className="flex items-center mt-8 text-black">
            {/* <input type="checkbox" className="mr-2" /> */}
            <label className="block text-sm font-medium ">
              Leave Information
            </label>
          </div>
          <div className="flex items-center gap-4">
            <div className="flex items-center mt-8 text-black">
              <input type="checkbox" className="mr-2" />
              <label className="block text-sm font-medium ">Add</label>
            </div>
            <div className="flex items-center mt-8 text-black">
              <input type="checkbox" className="mr-2" />
              <label className="block text-sm font-medium ">
                <> View</>
              </label>
            </div>
          </div>
          <div className="flex items-center gap-4">
            <div className="flex items-center mt-8 text-black">
              <input type="checkbox" className="mr-2" />
              <label className="block text-sm font-medium ">Edit</label>
            </div>
            <div className="flex items-center mt-8 text-black">
              <input type="checkbox" className="mr-2" />
              <label className="block text-sm font-medium ">
                <> Delete</>
              </label>
            </div>
          </div>

          <div className="flex items-center mt-8 text-purple-600">
            <input type="checkbox" className="mr-2" />
            <label className="block text-sm font-medium ">Reports</label>
          </div>

          <div className="flex items-center mt-8 text-black">
            {/* <input type="checkbox" className="mr-2" /> */}
            <label className="block text-sm font-medium ">
              Current Leave Balance with History of leaves
            </label>
          </div>
          <div className="flex items-center gap-4">
            <div className="flex items-center mt-8 text-black">
              <input type="checkbox" className="mr-2" />
              <label className="block text-sm font-medium ">Add</label>
            </div>
            <div className="flex items-center mt-8 text-black">
              <input type="checkbox" className="mr-2" />
              <label className="block text-sm font-medium ">
                <> View</>
              </label>
            </div>
          </div>
          <div className="flex items-center gap-4">
            <div className="flex items-center mt-8 text-black">
              <input type="checkbox" className="mr-2" />
              <label className="block text-sm font-medium ">Edit</label>
            </div>
            <div className="flex items-center mt-8 text-black">
              <input type="checkbox" className="mr-2" />
              <label className="block text-sm font-medium ">
                <> Delete</>
              </label>
            </div>
          </div>

          <div className="flex items-center mt-8 text-black">
            {/* <input type="checkbox" className="mr-2" /> */}
            <label className="block text-sm font-medium ">HR Reports</label>
          </div>
          <div className="flex items-center gap-4">
            <div className="flex items-center mt-8 text-black">
              <input type="checkbox" className="mr-2" />
              <label className="block text-sm font-medium ">Add</label>
            </div>
            <div className="flex items-center mt-8 text-black">
              <input type="checkbox" className="mr-2" />
              <label className="block text-sm font-medium ">
                <> View</>
              </label>
            </div>
          </div>
          <div className="flex items-center gap-4">
            <div className="flex items-center mt-8 text-black">
              <input type="checkbox" className="mr-2" />
              <label className="block text-sm font-medium ">Edit</label>
            </div>
            <div className="flex items-center mt-8 text-black">
              <input type="checkbox" className="mr-2" />
              <label className="block text-sm font-medium ">
                <> Delete</>
              </label>
            </div>
          </div>

          {/* <div className="flex items-center mt-8 text-black">
            <input type="checkbox" className="mr-2" />
            <label className="block text-sm font-medium ">
            Date Restrictions
            </label>
          </div>
          <div className="flex items-center gap-4">
            <div className="flex items-center mt-8 text-black">
              <input type="checkbox" className="mr-2" />
              <label className="block text-sm font-medium ">Add</label>
            </div>
            <div className="flex items-center mt-8 text-black">
              <input type="checkbox" className="mr-2" />
              <label className="block text-sm font-medium ">
                <> View</>
              </label>
            </div>
          </div>
          <div className="flex items-center gap-4">
            <div className="flex items-center mt-8 text-black">
              <input type="checkbox" className="mr-2" />
              <label className="block text-sm font-medium ">Edit</label>
            </div>
            <div className="flex items-center mt-8 text-black">
              <input type="checkbox" className="mr-2" />
              <label className="block text-sm font-medium ">
                <> Delete</>
              </label>
            </div>
          </div> */}
        </div>
        <div>
          <div className="border border-shadow bg-gray-50 w-21 rounded-md p-4">
            Settings
          </div>
          <div className="flex items-center mt-8 text-purple-600">
            <input type="checkbox" className="mr-2" />
            <label className="block text-sm font-medium ">Designation</label>
          </div>
          <div className="flex items-center gap-4">
            <div className="flex items-center mt-8 text-black">
              <input type="checkbox" className="mr-2" />
              <label className="block text-sm font-medium ">View</label>
            </div>
            <div className="flex items-center mt-8 text-black">
              <input type="checkbox" className="mr-2" />
              <label className="block text-sm font-medium ">
                <> Add</>
              </label>
            </div>
          </div>
          <div className="flex items-center gap-4">
            <div className="flex items-center mt-8 text-black">
              <input type="checkbox" className="mr-2" />
              <label className="block text-sm font-medium ">Update</label>
            </div>
            <div className="flex items-center mt-8 text-black">
              <input type="checkbox" className="mr-2" />
              <label className="block text-sm font-medium ">
                <> Delete</>
              </label>
            </div>
          </div>

          <div className="flex items-center mt-8 text-purple-600">
            <input type="checkbox" className="mr-2" />
            <label className="block text-sm font-medium ">
              User Permissions
            </label>
          </div>
          <div className="flex items-center gap-4">
            <div className="flex items-center mt-8 text-black">
              <input type="checkbox" className="mr-2" />
              <label className="block text-sm font-medium ">Create</label>
            </div>
            <div className="flex items-center mt-8 text-black">
              <input type="checkbox" className="mr-2" />
              <label className="block text-sm font-medium ">
                <> Edit</>
              </label>
            </div>
          </div>
          <div className="flex items-center gap-4">
            <div className="flex items-center mt-8 text-black">
              <input type="checkbox" className="mr-2" />
              <label className="block text-sm font-medium ">Delete</label>
            </div>
            <div className="flex items-center mt-8 text-black">
              <input type="checkbox" className="mr-2" />
              <label className="block text-sm font-medium ">
                <> View</>
              </label>
            </div>
          </div>
        </div>
      </div>
      {/* // button section */}
      <div className="flex gap-5 mt-10 text-center">
        <button className="text-purple-600 bg-white border border-black px-4  py-2 rounded-md shadow-sm ">
          Cancel
        </button>
        <button className="px-4 py-2 bg-[#8764A0] text-white rounded-md shadow-sm hover:bg-purple-600">
          save
        </button>
      </div>
    </div>
  );
};
export default Create_new_permission;
