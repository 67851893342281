import React from "react";

import { useState } from "react";
import { FaEdit, FaTrashAlt } from "react-icons/fa";



const Current_Leave_Balance_table = () => {
  const sampleData = [
    {
      leave_type: "test",
      date_of_leave_applied: "test",
      leave_requested_date: "test",
      remaining_leave: "test",
      reason: "test",

    },
    {
        leave_type: "test",
        date_of_leave_applied: "test",
        leave_requested_date: "test",
        remaining_leave: "test",
        reason: "test",
  
    },
    {
        leave_type: "test",
        date_of_leave_applied: "test",
        leave_requested_date: "test",
        remaining_leave: "test",
        reason: "test",
  
    },
  ];

  const [currentPage, setCurrentPage] = useState(1);
  const [isopenEditPopup, setopenEditPopup] = useState(false);

  
  const rowsPerPage = 5;

  // Calculate the total number of pages
  const totalPages = Math.ceil(sampleData.length / rowsPerPage);

  // Get the data for the current page
  const currentData = sampleData.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  // Change page
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };


  const toggleEditPopup = () => {
    setIsEditOpen(!isEditOpen);
  }

  const [isEditOpen, setIsEditOpen] = useState(false);


  return (
    <div className="p-4">
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-100">
          <tr>
            <th className="px-6 py-3 text-left text-md font-semibold text-gray-500 uppercase tracking-wider">
            Leave Type
            </th>
            <th className="px-6 py-3 text-left text-md font-semibold text-gray-500 uppercase tracking-wider">
             Date of Leave Applied 
            </th>
            <th className="px-6 py-3 text-left text-md font-semibold text-gray-500 uppercase tracking-wider">
              Leave Requested Date
            </th>
            <th className="px-6 py-3 text-left text-md font-semibold text-gray-500 uppercase tracking-wider">
              Remaining Leaves 
            </th>

            <th className="px-6 py-3 text-left text-md font-semibold text-gray-500 uppercase tracking-wider">
              Reason
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {currentData.map((employee) => (
            <tr key={employee.id}>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-md">
                {employee.id}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 text-md">
                {employee.emp_name}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 text-md">
                {employee.username}
              </td>

              <td className="flex items-center px-6 py-4 whitespace-nowrap text-sm font-medium ap-10 text-md">
              <button
                  className="text-black border-none"
                //   onClick={togglePopup}
                >
                  Click Here
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Pagination */}
      <div className="flex justify-between items-center py-3">
        <div>
          Showing{" "}
          {currentData.length > 0 ? (currentPage - 1) * rowsPerPage + 1 : 0} to{" "}
          {currentPage * rowsPerPage > sampleData.length
            ? sampleData.length
            : currentPage * rowsPerPage}{" "}
          of {sampleData.length} employees
        </div>
        <div className="flex space-x-2">
          {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
            <button
              key={page}
              onClick={() => handlePageChange(page)}
              className={`px-3 py-1 border rounded-md ${
                currentPage === page ? "bg-gray-300" : "bg-white"
              }`}
            >
              {page}
            </button>
          ))}
        </div>
      </div>

      {/* {isEditOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-800 bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-lg ">
           
            <button
              className="mt-4 bg-red-500 text-white px-4 py-2 rounded "
              onClick={toggleEditPopup}
            >
              Closes
            </button>
          </div>
        </div>
      )} */}
 
    </div>
  );
};




export default Current_Leave_Balance_table;
