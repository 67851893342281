

import React from 'react';

const LeavesData = [
  { color: 'bg-purple-500', label: 'Annual Leaves' },
  { color: 'bg-pink-500', label: 'Casual  Leaves' },
  { color: 'bg-yellow-500', label: 'Medical Leaves' },
  { color: 'bg-green-500', label: 'Special Leave' },
  { color: 'bg-orange-500', label: 'No Pay Leave' },
];

const Detailsofleaves = () => {
  return (
    <div className="border-2 border-black p-4 w-[200px] rounded-lg">
      <h2 className="text-2xl font-bold mb-4">Leaves</h2>
      <ul>
        {LeavesData.map((leave, index) => (
          <li key={index} className="flex items-center mb-2">
            <span className={`w-4 h-4 rounded-full ${leave.color} mr-2`} />
            <span>{leave.label}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Detailsofleaves;
