import React, { useState, useEffect } from "react";
import { MdKeyboardArrowDown } from "react-icons/md";
import moment from "moment";
import { RiMenu5Fill } from "react-icons/ri";
import { SlRefresh } from "react-icons/sl";
import { FaBagShopping } from "react-icons/fa6";
import { PiOfficeChairLight } from "react-icons/pi";
import { PiSealWarningLight } from "react-icons/pi";
import { CiAlarmOn } from "react-icons/ci";
import { TbBeach } from "react-icons/tb";
import Navbar from "../../navbar/navbar.jsx";
import Chart from "./chart.jsx";
import Leave_Details from "../../emp_management/dashboard/leave_details.jsx";
import Leave_bar_chart from "./leave_bar_chart.jsx";
import Detailsofleaves from "../details_of_leaves.jsx";

const Leave = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [leaveData, setLeaveData] = useState(null);
  const currentDate = moment().format("MMMM Do YYYY");
  const [currentTime, setCurrentTime] = useState(moment().format("h:mm:ss a"));

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(moment().format("h:mm:ss a"));
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://backbritish.talentfort.live/v1/hris/leave/GetLeaveCountstoallemployee"
        );
        const data = await response.json();
        setLeaveData(data[0]); // Set data for the first employee
      } catch (error) {
        console.error("Error fetching leave data:", error);
      }
    };
    fetchData();
  }, []);

  if (!leaveData) {
    return <div>Loading...</div>;
  }

  return (
    <div className="mx-10 mt-5">
      {/* top layer */}
      <Navbar />

      {/* second layer */}
      <div className="flex justify-between  items-center mt-6">
        <div>
          <p className="text-[30px] font-semibold">Dashboard - Leave</p>
        </div>
        <div className="flex gap-6 items-center">
          <div>
            <div className="text-[#3D0B5E] text-[20px] font-bold">{currentDate}</div>
          </div>
          <div className="text-[20px] font-bold">{currentTime}</div>
        </div>
      </div>

      {/* button with dropdown */}
      <div className="flex gap-5 items-center mt-8">
        <div className="relative">
          <button
            className="p-3 border border-black rounded-[12px]"
            onClick={toggleDropdown}
          >
            <div className="flex gap-3 items-center">
              <div>All Department</div>
              <MdKeyboardArrowDown />
            </div>
          </button>
          {isDropdownOpen && (
            <div className="absolute mt-2 w-48 bg-white border border-gray-300 rounded-lg shadow-lg">
              <ul>
                <li className="p-2 hover:bg-gray-200 cursor-pointer">
                  Department 1
                </li>
                <li className="p-2 hover:bg-gray-200 cursor-pointer">
                  Department 2
                </li>
                <li className="p-2 hover:bg-gray-200 cursor-pointer">
                  Department 3
                </li>
              </ul>
            </div>
          )}
        </div>
        <div>
          <button className="p-3 border border-black rounded-[12px]">
            <div className="flex gap-3 items-center">
              Filter <RiMenu5Fill />
            </div>
          </button>
        </div>

        <div>
          <SlRefresh />
        </div>
      </div>

      {/* card layer */}
      <div className="mt-10">
        <div className="grid grid-cols-5 grid-flow-rows gap-5">
          {/* card 1 */}
          <div className="border border-black p-3 rounded-xl">
            <div className="flex justify-between items-center mt-5">
              <div>
                <FaBagShopping className="text-purple-600" />
              </div>

              <div>
                <p className="font-semibold text-green-400">Per Annum</p>
              </div>
            </div>
            <p className="mt-5 font-semibold">Total Annual Leave Count</p>
            <p className="text-[30px] font-semibold">
              {leaveData.actual_leave_counts.anualleavecount}
            </p>
          </div>
          {/* card 2 */}
          <div className="border border-black p-3 rounded-xl">
            <div className="flex justify-between items-center mt-5">
              <div>
                <PiOfficeChairLight className="text-purple-600" />
              </div>

              <div>
                <p className="font-semibold text-green-400">Per Annum</p>
              </div>
            </div>
            <p className="mt-5 font-semibold">Total Casual Leave Count</p>
            <p className="text-[30px] font-semibold">
              {leaveData.actual_leave_counts.casualleavecount}
            </p>
          </div>
          {/* card 3 */}
          <div className="border border-black p-3 rounded-xl">
            <div className="flex justify-between items-center mt-5">
              <div>
                <PiSealWarningLight className="text-purple-600" />
              </div>

              <div className="">
                <p className="font-semibold text-green-400">Per Annum</p>
              </div>
            </div>
            <p className="mt-5 font-semibold">Total Medical Leave Count</p>
            <p className="text-[30px] font-semibold">
              {leaveData.actual_leave_counts.medicalleavecount}
            </p>
          </div>
          {/* card 4 */}
          <div className="border border-black p-3 rounded-xl">
            <div className="flex justify-between items-center mt-5">
              <div>
                <CiAlarmOn className="text-purple-600" />
              </div>

              <div className="">
                <p className="font-semibold text-green-400">Per Annum</p>
              </div>
            </div>
            <p className="mt-5 font-semibold">Total Special Leave Count</p>
            <p className="text-[30px] font-semibold">
              {leaveData.actual_leave_counts.specialeavecount}
            </p>
          </div>

          {/* card 5 */}
          <div className="border border-black p-3 rounded-xl">
            <div className="flex justify-between items-center mt-5">
              <div>
                <TbBeach className="text-purple-600" />
              </div>

              <div className="">
                <p className="font-semibold text-green-400">Per Annum</p>
              </div>
            </div>
            <p className="mt-5 font-semibold">Total No pay Leave Count</p>
            <p className="text-[30px] font-semibold">
              {leaveData.actual_leave_counts.nopayleavecount}
            </p>
          </div>
        </div>
      </div>
      {/* lower layer */}

      <div className="flex gap-3 items-center w-full">
        {/* section1 */}
        <div className="flex-auto">
          <Detailsofleaves />
        </div>

        {/* section2 */}
        <div className="flex-auto">
          <Chart />
        </div>
        <div className="flex-auto mt-8">
          <Leave_bar_chart />
        </div>
      </div>
    </div>
  );
};

export default Leave;
