import React, { useState, useEffect } from "react";
import Navbar from "../navbar/navbar";
import moment from "moment";
import { MdKeyboardArrowDown } from "react-icons/md";
import { CiSearch } from "react-icons/ci";
import { RiMenu5Fill } from "react-icons/ri";
import { CiCirclePlus } from "react-icons/ci";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import { Link } from "react-router-dom";
const Permissions = () => {
  const [isModalOpen, setIsModalOpen] = useState(false); // State to manage modal visibility
  const currentDate = moment().format("MMMM Do YYYY");
  const [currentTime, setCurrentTime] = useState(moment().format("h:mm:ss a"));
  const [showPermissionForm, setShowPermissionForm] = useState(false);
  const handleButtonClick = () => {
    setShowPermissionForm(true);
  };
  // Usage
  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(moment().format("h:mm:ss a"));
    }, 1000);
    return () => clearInterval(timer);
  }, []);
  const toggleForm = () => {
    setIsFormOpen(!isFormOpen);
  };
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [issetShowPopup, setShowPopup] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  const handleCreateUserClick = () => {
    setShowPopup(true);
  };
  const handleClosePopup = () => {
    setShowPopup(false);
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const rowsPerPage = 5;
  const sampleData = [
    {
      emp_no: 555,
      role: "manager 1",
      role_description: "test_user 1",
      // granted_permissios: "",
    },
    {
      emp_no: 510,
      role: "manager 2",
      role_description: "test_user 2",
    },
    {
      emp_no: 559,
      role: "manager 3",
      role_description: "test_user 3",
    },
  ];
  const toggleEditPopup = () => {
    setIsEditOpen(!isEditOpen);
  };
  // Calculate the total number of pages
  const totalPages = Math.ceil(sampleData.length / rowsPerPage);
  const currentData = sampleData.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );
  return (
    <div className="mx-10 mt-5 overflow-y-auto ">
      <Navbar />
      <div className="flex justify-between mt-6">
        <p className="text-[30px] font-semibold">User Permissions</p>
        <div className="flex gap-6 items-center">
          <div>
            <div className="text-[#3D0B5E] text-[20px] font-bold">
              {currentDate}
            </div>
          </div>
          <div className="text-[20px] font-bold">{currentTime}</div>
        </div>
      </div>
      {/* third layer */}
      <div className="mt-5">
        <div className="px-5 py-2 bg-[#8764A0] text-white rounded-md shadow-sm hover:bg-purple-600 w-60">
          <div className="flex items-center justify-between">
            <Link to="/create-user-permission">
              <button>Create new permission </button>
            </Link>
            <div>
              <CiCirclePlus />
            </div>
          </div>
        </div>
        <div className="flex justify-between items-center">
          <div className="flex gap-4 items-center mt-5">
            <div className="relative">
              <input
                className="border border-black rounded-xl p-2 pl-10 w-[325px]"
                placeholder="Search by ID or Department"
              />
              <CiSearch className="absolute top-1/2 left-3 transform -translate-y-1/2 text-gray-500" />
            </div>
            <div>
              <button className="p-2 border border-black rounded-[12px]">
                <div className="flex gap-3 items-center">
                  Filter <RiMenu5Fill />
                </div>
              </button>
            </div>
          </div>
          <div className=" flex gap-6 relative">
            <button
              className="p-3 border border-black rounded-[12px]"
              onClick={toggleDropdown}
            >
              <div className="flex gap-3 items-center">
                <div>Job Role</div>
                <MdKeyboardArrowDown />
              </div>
            </button>
            {isDropdownOpen && (
              <div className="absolute mt-2 w-48 bg-white border border-gray-300 rounded-lg shadow-lg">
                <ul>
                  <li className="p-2 hover:bg-gray-200 cursor-pointer">
                    {/* Department 1 */}
                  </li>
                  <li className="p-2 hover:bg-gray-200 cursor-pointer">
                    {/* Department 2 */}
                  </li>
                  <li className="p-2 hover:bg-gray-200 cursor-pointer">
                    {/* Department 3 */}
                  </li>
                </ul>
              </div>
            )}
            <div>
              <input
                type="date"
                className="border border-black rounded-xl p-2 "
              />
            </div>
          </div>
        </div>
      </div>
      <div className="mt-5"></div>
      {/* table */}
      <div>
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-100">
            <tr>
              <th className="px-6 py-3 text-left text-md font-semibold text-gray-500 uppercase tracking-wider">
                Employee No.
              </th>
              <th className="px-6 py-3 text-left text-md font-semibold text-gray-500 uppercase tracking-wider">
                Role
              </th>
              <th className="px-6 py-3 text-left text-md font-semibold text-gray-500 uppercase tracking-wider">
                Role Description
              </th>
              <th className="px-6 py-3 text-left text-md font-semibold text-gray-500 uppercase tracking-wider">
                Granted Permissions
              </th>
              <th className="px-6 py-3 text-left text-md font-semibold text-gray-500 uppercase tracking-wider">
                Action
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {currentData.map((employee) => (
              <tr key={employee.emp_no}>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-md">
                  {employee.emp_no}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 text-md">
                  {employee.role}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 text-md">
                  {employee.role_description}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 text-md">
                  <button className="text-black border-none">Click Here</button>
                </td>
                <td className="flex items-center px-6 py-4 whitespace-nowrap text-sm font-medium ap-10 text-md">
                  <div className="flex items-center gap-3">
                    {" "}
                    {/* <button
                      className="text-blue-500 rounded-lg gap-40"
                      onClick={toggleEditPopup}
                    >
                      <FaEdit />
                    </button> */}
                    <Link to="/edit-user-permission">
                      <button
                        className="text-blue-500 rounded-lg gap-40"
                        onClick={toggleEditPopup}
                      >
                        <FaEdit />
                      </button>
                    </Link>
                    <FaTrashAlt className="text-red-500 cursor-pointer inline" />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default Permissions;
