import React, { useState, useEffect } from "react";
import { MdKeyboardArrowDown } from "react-icons/md";
import moment from "moment";
import { FaBagShopping } from "react-icons/fa6";
import Navbar from "../../navbar/navbar.jsx";
import { PiOfficeChair } from "react-icons/pi";
import { PiSealWarningBold } from "react-icons/pi";
import { LuAlarmClock } from "react-icons/lu";
import { TbBeach } from "react-icons/tb";

import Emp_Chart from "./emp_Chart.jsx";
import Leave_Details from "./leave_details.jsx";
import Emp_barchart from "./emp_barchart.jsx";

const Dashboard = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const currentDate = moment().format("MMMM Do YYYY");
  const [currentTime, setCurrentTime] = useState(moment().format("h:mm:ss a"));

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(moment().format("h:mm:ss a"));
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <div className="mx-10 mt-5">
      {/* top layer */}
      <Navbar />

      {/* second layer */}
      <div className="flex justify-between items-center mt-6">
        <div>
          <p className="text-[30px] font-semibold">
            Dashboard - Employee Information Management
          </p>
        </div>
        <div className="flex gap-6 items-center">
          <div>
            <div className="text-[#3D0B5E] text-[20px] font-bold">
              {currentDate}
            </div>
          </div>
          <div className="text-[20px] font-bold">{currentTime}</div>
        </div>
      </div>

      {/* card layer */}
      <div className="mt-10">
        <div className="grid grid-cols-5 grid-flow-rows gap-5">
          {/* card 1 */}
          <div className="border border-black p-3 rounded-xl">
            <div className="flex justify-between items-center mt-5">
              <div>
                <FaBagShopping className="w-8 h-8" />
              </div>

              <div className="">
                <p className="font-semibold text-green-400">{currentTime}</p>
              </div>
            </div>
            <p className="mt-5 font-semibold">Total Workforce</p>
            <p className="text-[30px] font-semibold">150</p>
          </div>
          {/* card 2 */}
          <div className="border border-black p-3 rounded-xl">
            <div className="flex justify-between items-center mt-5">
              <div>
                <PiOfficeChair className="w-8 h-8" />
              </div>

              <div>
                <p className="font-semibold text-green-400">{currentTime}</p>
              </div>
            </div>
            <p className="mt-5 font-semibold">Present Workforce</p>
            <p className="text-[30px] font-semibold">125</p>
          </div>
          {/* card 3 */}
          <div className="border border-black p-3 rounded-xl">
            <div className="flex justify-between items-center mt-5">
              <div>
                <PiSealWarningBold className="w-8 h-8" />
              </div>

              <div className="">
                <p className="font-semibold text-green-400">{currentTime}</p>
              </div>
            </div>
            <p className="mt-5 font-semibold">Absent Workforce</p>
            <p className="text-[30px] font-semibold">15</p>
          </div>
          {/* card 4 */}
          <div className="border border-black p-3 rounded-xl">
            <div className="flex justify-between items-center mt-5">
              <div>
                <LuAlarmClock className="w-8 h-8" />
              </div>

              <div className="">
                <p className="font-semibold text-green-400">{currentTime}</p>
              </div>
            </div>
            <p className="mt-5 font-semibold">Late Arrivals</p>
            <p className="text-[30px] font-semibold">15</p>
          </div>

          {/* card 5 */}
          <div className="border border-black p-3 rounded-xl">
            <div className="flex justify-between items-center mt-5">
              <div>
                <TbBeach className="w-8 h-8" />
              </div>

              <div className="">
                <p className="font-semibold text-green-400">{currentTime}</p>
              </div>
            </div>
            <p className="mt-5 font-semibold">In Leave</p>
            <p className="text-[30px] font-semibold">5</p>
          </div>
        </div>
      </div>
      {/* lower layer */}

      <div className="flex gap-3 items-center w-full">
        {/* section1 */}
        <div className="flex-auto">
          <Leave_Details />
        </div>

        {/* section2 */}
        <div className="flex-auto">
          <Emp_Chart />
        </div>

        <div className="flex-auto mt-10">
          <Emp_barchart />
        </div>
      </div>
    </div>
  );
};
export default Dashboard;
