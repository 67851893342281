import React, { useState, useEffect } from "react";
import Navbar from "./../../navbar/navbar";
import moment from "moment";
import { MdKeyboardArrowDown } from "react-icons/md";

import { CiSearch } from "react-icons/ci";
import { RiMenu5Fill } from "react-icons/ri";
import User_account_creation_table from "./user_account_creation_table";
import { CiCirclePlus } from "react-icons/ci";
import User_account_popup from "./user_account_popup";
const User_account_creation = () => {
  const [isModalOpen, setIsModalOpen] = useState(false); // State to manage modal visibility

  const currentDate = moment().format("MMMM Do YYYY");
  const [currentTime, setCurrentTime] = useState(moment().format("h:mm:ss a"));

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(moment().format("h:mm:ss a"));
    }, 1000);
    return () => clearInterval(timer);
  }, []);
  const toggleForm = () => {
    setIsFormOpen(!isFormOpen);
  };
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [issetShowPopup, setShowPopup] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  const handleCreateUserClick = () => {
    setShowPopup(true);
  };
  const handleClosePopup = () => {
    setShowPopup(false);
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  return (
    <div className="mx-10 mt-5 overflow-y-auto ">
      <Navbar />

      <div className="flex justify-between mt-6">
        <p className="text-[30px] font-semibold">User Account</p>
        <div className="flex gap-6 items-center">
          <div>
            <div className="text-[#3D0B5E] text-[20px] font-bold">
              {currentDate}
            </div>
          </div>
          <div className="text-[20px] font-bold">{currentTime}</div>
        </div>
      </div>

      {/* third layer */}
      <div className="mt-5">
        <div className="px-5 py-2 bg-[#8764A0] text-white rounded-md shadow-sm w-60">
          <div
            className="flex items-center justify-between"
            onClick={handleOpenModal}
          >
            <button>Create User Account </button>
            <div>
              <CiCirclePlus />
            </div>
          </div>
        </div>
        <div className="flex justify-between items-center">
          <div className="flex gap-4 items-center mt-5">
            <div className="relative">
              <input
                className="border border-black rounded-xl p-2 pl-10 w-[325px]"
                placeholder="Search by ID or Department"
              />
              <CiSearch className="absolute top-1/2 left-3 transform -translate-y-1/2 text-gray-500" />
            </div>
            <div>
              <button className="p-2 border border-black rounded-[12px]">
                <div className="flex gap-3 items-center">
                  Filter <RiMenu5Fill />
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-5"></div>

      {/* table */}
      <div>
        <User_account_creation_table />
      </div>
      {/* Modal for User_account_popup */}
      {isModalOpen && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-200 bg-opacity-80 z-50">
          <div className="bg-white rounded-lg p-8">
            <User_account_popup onClose={handleCloseModal} />
          </div>
        </div>
      )}
    </div>
  );
};

export default User_account_creation;
