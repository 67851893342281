import React, { useState, useEffect } from "react";
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';

const data = [
  { name: "Special Leave", value: 10, fill: "#FF00FF" },
  { name: "Annual Leave", value: 15, fill: "#FFFF99" },
  { name: "Sick Leave", value: 25, fill: "#99FF99" },
  { name: "Casual Leave", value: 12, fill: "#FF9933" },
  { name: "Medical Leave", value: 18, fill: "#CC99FF" },
];

const COLORS = ['#FF00FF', '#FFFF99', '#99FF99', '#FF9933', '#CC99FF'];

const RADIAN = Math.PI / 180;

const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};




const Example = () => {
  // const [leaveData, setLeaveData] = useState(null);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await fetch(
  //         "https://backbritish.talentfort.live/v1/hris/leave/GetLeaveCountstoallemployee"
  //       );
  //       const data = await response.json();
  //       setLeaveData(data[0]); // Set data for the first employee
  //     } catch (error) {
  //       console.error("Error fetching leave data:", error);
  //     }
  //   };
  //   fetchData();
  // }, []);

  // if (!leaveData) {
  //   return <div>Loading...</div>;
  // }
  return (
    <ResponsiveContainer width="100%" height={400}>
      <PieChart>
        <Pie
          data={data}
          cx="50%"
          cy="50%"
          labelLine={false}
          label={renderCustomizedLabel}
          outerRadius={80}
          fill="#8884d8"
          dataKey="value"
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
};

export default Example;
