import React, { useEffect, useState } from "react";
import { FaEdit, FaTrashAlt } from "react-icons/fa";

const Checkin_checkout_report_table = ({ checkIn_report_Id, onClose }) => {
  const [formData, setFormData] = useState({
    tableName: "",
    date: "",
    employeeNumber: "",
    employeeName: "",
    check_In_Time: "",
    department: "",
    timetable: "",
    employees: [],
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [isopenEditPopup, setopenEditPopup] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [employeeData, setEmployeeData] = useState([]); // Initialize as an empty array
  const [selectedEmployees, setSelectedEmployees] = useState([]);

  const rowsPerPage = 5;

  // Calculate the total number of pages
  const totalPages = Math.ceil(formData.employees.length / rowsPerPage);

  // Change page
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const toggleEditPopup = () => {
    setIsEditOpen(!isEditOpen);
  };

  const [isEditOpen, setIsEditOpen] = useState(false);

  const filteredEmployeeData = employeeData.filter((employee) =>
    employee.employee_name_initial
      .toLowerCase()
      .includes(searchQuery.toLowerCase())
  );

  useEffect(() => {
    if (checkIn_report_Id) {
      fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/v1/hris/timetable/gettimetablebyid?timetableID=${checkIn_report_Id}`
      )
        .then((response) => response.json())
        .then((data) => {
          setFormData({
            checkIntableName: data.checkIntableName,
            date: data.date,
            employeeNumber: data.employeeNumber,
            employeeName: data.employeeName,
            check_In_Time: data.check_In_Time,
            department: data.department,
            timetable: data.timetable,
            employees: data.employees || [],
          });

          // Fetch all employee data
          fetch(
            `${process.env.REACT_APP_API_ENDPOINT}/v1/hris/employees/getemployeebasicdetails`
          )
            .then((response) => response.json())
            .then((employees) => {
              setEmployeeData(Array.isArray(employees) ? employees : []);

              // Filter and set selected employees based on checkIn table data
              const selected = employees.filter((emp) =>
                data.employees.some((e) => e.employee_no === emp.employee_no)
              );
              setSelectedEmployees(selected);
            })
            .catch((error) =>
              console.error("Error fetching employee data:", error)
            );
        })
        .catch((error) =>
          console.error("Error fetching timetable data:", error)
        );
    }
  }, [checkIn_report_Id]);

  return (
    <div className="p-4">
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-100">
          <tr>
            <th className="px-6 py-3 text-left text-md font-semibold text-gray-500 uppercase tracking-wider">
              Date
            </th>
            <th className="px-6 py-3 text-left text-md font-semibold text-gray-500 uppercase tracking-wider">
              Employee Number
            </th>
            <th className="px-6 py-3 text-left text-md font-semibold text-gray-500 uppercase tracking-wider">
              Employee Name
            </th>
            <th className="px-6 py-3 text-left text-md font-semibold text-gray-500 uppercase tracking-wider">
              Check-In Time
            </th>
            <th className="px-6 py-3 text-left text-md font-semibold text-gray-500 uppercase tracking-wider">
              Check-out Time
            </th>
            <th className="px-6 py-3 text-left text-md font-semibold text-gray-500 uppercase tracking-wider">
              Department
            </th>
            <th className="px-6 py-3 text-left text-md font-semibold text-gray-500 uppercase tracking-wider">
              Timetable
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {formData.employees.map((employee) => (
            <tr key={employee.employee_no}>
              <td className="px-6 py-4 whitespace-nowrap text-t  text-gray-500">
                {employee.date}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-t  text-gray-900">
                {employee.employee_no}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-t  text-gray-900">
                {employee.employee_name_initial}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-t  text-gray-900">
                {employee.check_in_time}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-t  text-gray-900">
                {employee.check_out_time}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-t  text-gray-900">
                {employee.department}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-t  text-gray-900">
                {employee.timetable}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Pagination */}
      <div className="flex justify-between items-center py-3">
        <div>
          Showing{" "}
          {formData.employees.length > 0
            ? (currentPage - 1) * rowsPerPage + 1
            : 0}{" "}
          to{" "}
          {currentPage * rowsPerPage > formData.employees.length
            ? formData.employees.length
            : currentPage * rowsPerPage}{" "}
          of {formData.employees.length} employees
        </div>
        <div className="flex space-x-2">
          {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
            <button
              key={page}
              onClick={() => handlePageChange(page)}
              className={`px-3 py-1 border rounded-md ${
                currentPage === page ? "bg-gray-300" : "bg-white"
              }`}
            >
              {page}
            </button>
          ))}
        </div>
      </div>

      <div className="overflow-y-auto max-h-64">
        <table className="table-auto w-full">
          <tbody>
            {filteredEmployeeData.map((employee) => (
              <tr
                key={employee.employee_no}
                className="border-b border-black-300"
              >
                <td className="px-4 py-2 flex items-center">
                  <input
                    type="checkbox"
                    className="mr-2"
                    checked={selectedEmployees.some(
                      (e) => e.employee_no === employee.employee_no
                    )}
                  />
                  {employee.employee_no}
                </td>
                <td className="px-4 py-2">{employee.employee_name_initial}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {isEditOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-800 bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-lg ">
            <button
              className="mt-4 bg-red-500 text-white px-4 py-2 rounded "
              onClick={toggleEditPopup}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Checkin_checkout_report_table;
