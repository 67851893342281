import React, { useState, useEffect } from "react";
import Navbar from "../../navbar/navbar";
import moment from "moment";
import { CiSearch } from "react-icons/ci";
import { MdKeyboardArrowDown } from "react-icons/md";
import LeaveDataTable from "./emp_leave_table";
import AddLeaveQuotaForm from "./add_leave_quota";

const EmpLeaveManagement = () => {
  const [currentTime, setCurrentTime] = useState(moment().format("h:mm:ss a"));
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [leaveData, setLeaveData] = useState([]); // State to hold leave data
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const currentDate = moment().format("MMMM Do YYYY");

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(moment().format("h:mm:ss a"));
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const toggleForm = () => {
    setIsFormOpen(!isFormOpen);
  };

  const handleSearchChange = (e) => {
    setSearchInput(e.target.value);
  };

  const handleResetLeaveCount = () => {
    setShowConfirmDialog(true);
  };

  const confirmResetLeaveCount = async () => {
    try {
      const response = await fetch(
        "https://backbritish.talentfort.live/v1/hris/leave/resetleavecounts",
        {
          method: "PUT",
        }
      );

      if (!response.ok) {
        throw new Error("Failed to reset leave counts");
      }

      const data = await response.json();
      setSuccessMessage(data.message);

      // Update the leaveData state to reset current_leave_counts
      const updatedLeaveData = leaveData.map((employee) => ({
        ...employee,
        current_leave_counts: {
          shortleavecount: 0,
          halfdaycount: 0,
          anualleavecount: 0,
          casualleavecount: 0,
          medicalleavecount: 0,
        },
      }));

      setLeaveData(updatedLeaveData);
      setShowConfirmDialog(false);
    } catch (error) {
      console.error("Error resetting leave counts:", error);
      setSuccessMessage("Error resetting leave counts");
    }
  };

  return (
    <div className="mx-10 mt-5">
      <Navbar />
      <div className="overflow-hidden">
        <div className="flex justify-between items-center mt-6">
          <div>
            <p className="text-[30px] font-semibold">
              Employee Leave Management
            </p>
            <p className="text-[15px] font-semibold text-[#6A2E92]">
              Employee Leave Management
            </p>
          </div>
          <div className="flex gap-6 items-center">
            <div>
              <div className="text-[#3D0B5E] text-[20px] font-bold">
                {currentDate}
              </div>
            </div>
            <div className="text-[20px] font-bold">{currentTime}</div>
          </div>
        </div>
        <div className="mt-5">
          <div>
            <button
              className="bg-[#6A2E92] text-white p-3 rounded-[12px] font-semibold"
              onClick={handleResetLeaveCount}
            >
              Reset leave Count
            </button>
          </div>
          <div className="flex justify-between items-center">
            <div className="flex items-center">
              <div className="relative">
                <input
                  className="border border-black rounded-xl p-2 pl-10 w-[325px]"
                  placeholder="Search by ID or Department"
                  value={searchInput}
                  onChange={handleSearchChange}
                />
                <CiSearch className="absolute top-1/2 left-3 transform -translate-y-1/2 text-gray-500" />
              </div>
            </div>
            <div className="relative">
              <button
                className="p-3 border border-black rounded-[12px]"
                onClick={toggleDropdown}
              >
                <div className="flex gap-3 items-center">
                  <div>All Department</div>
                  <MdKeyboardArrowDown />
                </div>
              </button>
              {isDropdownOpen && (
                <div className="absolute mt-2 w-48 bg-white border border-gray-300 rounded-lg shadow-lg">
                  <ul>
                    <li className="p-2 hover:bg-gray-200 cursor-pointer">
                      Department 1
                    </li>
                    <li className="p-2 hover:bg-gray-200 cursor-pointer">
                      Department 2
                    </li>
                    <li className="p-2 hover:bg-gray-200 cursor-pointer">
                      Department 3
                    </li>
                  </ul>
                </div>
              )}
            </div>
            <div>
              <input
                type="date"
                className="border border-black rounded-xl p-2 "
              />
            </div>
          </div>
        </div>
      </div>
      <div className="overflow-x-auto">
        <LeaveDataTable searchInput={searchInput} leaveData={leaveData} />
      </div>
      {isFormOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-800 bg-opacity-50">
          <div className="bg-white p-6 rounded-lg w-3/4 max-w-lg">
            <AddLeaveQuotaForm />
            <button
              className="mt-4 bg-red-500 text-white px-4 py-2 rounded"
              onClick={toggleForm}
            >
              Close
            </button>
          </div>
        </div>
      )}
      {showConfirmDialog && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-800 bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <p>Are you sure you want to reset all leave counts to 0?</p>
            <div className="mt-4 flex justify-end">
              <button
                className="bg-red-500 text-white px-4 py-2 rounded mr-2"
                onClick={confirmResetLeaveCount}
              >
                Yes
              </button>
              <button
                className="bg-gray-300 text-black px-4 py-2 rounded"
                onClick={() => setShowConfirmDialog(false)}
              >
                No
              </button>
            </div>
          </div>
        </div>
      )}
      {successMessage && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-800 bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <p>{successMessage}</p>
            <button
              className="mt-4 bg-[#6A2E92] text-white px-4 py-2 rounded"
              onClick={() => setSuccessMessage("")}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default EmpLeaveManagement;
