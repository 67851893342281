import React, { useState, useEffect, useMemo } from "react";
import { FaArrowRight } from "react-icons/fa";
import Leave_process_popup from "./leave_process_popup";
import Leave_Request_Popup from "./leave_request_popup";

const Leave_request_table = ({ searchInput }) => {
  const [leaveData, setLeaveData] = useState([]);
  // const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 5;
  const [isOpen, setIsOpen] = useState(false);
  const [isRqstOpen, setIsRqstOpen] = useState(false);
  const [currentEmployee, setCurrentEmployee] = useState(null);
  const [selectedEmployeeNo, setSelectedEmployeeNo] = useState(null);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch(
        "https://backbritish.talentfort.live/v1/hris/leave/getleave"
      );

      const result = await response.json();

      setLeaveData(result);
    } catch (error) {
      console.error("Error fetching requested leave data:", error);
    }
  };




  //created another Hook for fileredData.
  const filteredData = useMemo(() => {
    return leaveData.filter((leave) =>
      leave.employee_no.toLowerCase().includes(searchInput.toLowerCase())
    );
  }, [searchInput, leaveData]);

  console.log(filteredData);

  // Pagination
  const totalPages = Math.ceil(filteredData.length / rowsPerPage);

  // Data for current page
  const currentData = filteredData.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const togglePopup = (employee) => {
    setCurrentEmployee(employee);
    setIsOpen(!isOpen);
  };

  const toggleRqstPopup = (employeeNo) => {
    setSelectedEmployeeNo(employeeNo);
    setIsRqstOpen(!isRqstOpen);
  };
  const getLeaveTypeClass = (leaveType) => {
    switch (leaveType.toLowerCase()) {
      case "casual":
        return "bg-orange-400 text-white";
      case "anual":
        return "bg-green-100 text-green-800";
      case "special":
        return "bg-pink-500 text-white";
      case "medical":
        return "bg-pink-100 text-pink-800";
      default:
        return "bg-gray-100 text-gray-800";
    }
  };

  return (
    <div className="p-4">
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-100">
          <tr>
            <th className="px-6 py-3 text-left text-md font-semibold text-gray-500 uppercase tracking-wider">
              ID
            </th>
            <th className="px-6 py-3 text-left text-md font-semibold text-gray-500 uppercase tracking-wider">
              Employee Name
            </th>
            <th className="px-6 py-3 text-left text-md font-semibold text-gray-500 uppercase tracking-wider">
              Department
            </th>
            <th className="px-6 py-3 text-left text-md font-semibold text-gray-500 uppercase tracking-wider">
              Date of Leave Applied
            </th>
            <th className="px-6 py-3 text-left text-md font-semibold text-gray-500 uppercase tracking-wider">
              Leave Category
            </th>
            <th className="px-6 py-3 text-left text-md font-semibold text-gray-500 uppercase tracking-wider">
              Leave Requested Date
            </th>
            <th className="px-6 py-3 text-left text-md font-semibold text-gray-500 uppercase tracking-wider">
              Leaves taken under each leave category
            </th>
            <th className="px-6 py-3 text-left text-md font-semibold text-gray-500 uppercase tracking-wider">
              Reason
            </th>
            <th className="px-6 py-3 text-left text-md font-semibold text-gray-500 uppercase tracking-wider">
              Actions
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {currentData.map((leave, i) => (
            <tr key={leave.employee_no + "_" + i}>
              <td className="px-6 py-4 whitespace-nowrap text-md text-gray-500">
                {leave.employee_no}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-md text-gray-900">
                {leave.employee_fullname}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-md text-gray-900">
                <span className="px-2 inline-flex text-md leading-5 font-semibold rounded-full">
                  {leave.department} 
                </span>
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-md text-gray-500">
                {leave.requesting_date}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-md text-gray-500">
                {leave.leave_type}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-md text-gray-500">
                {leave.requested_date}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-md text-gray-500 font-semibold underline cursor-pointer">
                <button
                  className="text-black border-none"
                  onClick={() => togglePopup(leave)}
                >
                  Click Here
                </button>
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-md text-gray-500">
                {leave.reason}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                <button
                  className="bg-[#6A2E92] p-2 text-white rounded-lg"
                  onClick={() => toggleRqstPopup(leave.employee_no)}
                >
                  <FaArrowRight />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Pagination */}
      <div className="flex justify-between items-center py-3">
        <div>
          Showing{" "}
          {currentData.length > 0 ? (currentPage - 1) * rowsPerPage + 1 : 0} to{" "}
          {currentPage * rowsPerPage > filteredData.length
            ? filteredData.length
            : currentPage * rowsPerPage}{" "}
          of {filteredData.length} employees
        </div>
        <div className="flex space-x-2">
          {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
            <button
              key={page}
              onClick={() => handlePageChange(page)}
              className={`px-3 py-1 border rounded-md ${
                currentPage === page ? "bg-gray-300" : "bg-white"
              }`}
            >
              {page}
            </button>
          ))}
        </div>
      </div>

      {/* Leave Process Popup */}
      {isOpen && currentEmployee && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-800 bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-3/4">
            {/* <Leave_process_popup
              onClose={() => setIsOpen(false)}
              employeeNo={currentEmployee.employee_no}
            /> */}
             <Leave_process_popup togglePopup = {togglePopup}/>
          </div>
        </div>
      )}

      {/* Leave Request Popup */}
      {isRqstOpen && selectedEmployeeNo && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-800 bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-3/4 relative">
            <button
              className="absolute top-2 right-2 text-gray-500"
              onClick={() => setIsRqstOpen(false)}
            >
              Close
            </button>
            <Leave_Request_Popup
              employeeNo={selectedEmployeeNo}
              onClose={() => setIsRqstOpen(false)}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Leave_request_table;