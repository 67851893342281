import React, { useState,useEffect } from "react";
import Navbar from "../navbar/navbar";
import moment from "moment";
import { CiSearch } from "react-icons/ci";
import { RiMenu5Fill } from "react-icons/ri";
import { MdKeyboardArrowDown } from "react-icons/md";
import Papa from "papaparse";
import { saveAs } from "file-saver";

const data = [
  // Sample data
  {
    date: "2024-07-18",
    empNo: "001",
    empName: "John Doe",
    dept: "HR",
    leaveType: "Sick",
    reason: "Fever",
  },
  // Add more sample data here
];

const Absence_Report = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedRows, setSelectedRows] = useState([]);
  const rowsPerPage = 5;
  const currentDate = moment().format("MMMM Do YYYY");
  const [currentTime, setCurrentTime] = useState(moment().format("h:mm:ss a"));

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(moment().format("h:mm:ss a"));
    }, 1000);
    return () => clearInterval(timer);
  }, []);


  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const paginatedData = data.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  const handleSelectRow = (index) => {
    setSelectedRows((prevSelectedRows) =>
      prevSelectedRows.includes(index)
        ? prevSelectedRows.filter((i) => i !== index)
        : [...prevSelectedRows, index]
    );
  };

  // const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  // const toggleDropdown = () => {
  //   setIsDropdownOpen(!isDropdownOpen);
  // };
  // const currentTime = moment().format("MMMM Do YYYY, h:mm:ss a");

  // Export to CSV function
  const exportToCSV = () => {
    const csvData = sampleData.map((employee) => ({
      ID: employee.id,
      FullName: employee.name,
      Department: employee.department.name,
      Name_With_Initials: employee.name,
      Calling_Name: employee.name,
      NIC: employee.name,
      DateofBirth: employee.name,
      "DateofBirth": employee.DateofBirth,
      Gender:employee.name,
      Marital_Status: employee.name,
      Contact_number: employee.name,
      permanentAddress: employee.name,
      Temporary_Address : employee.name,
      Email_Address : employee.name,

      "Date of Appointment": employee.annualLeave,
      
    }));

    const csv = Papa.unparse(csvData);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    saveAs(blob, "employee_data.csv");
  };


  const sampleData = [
    {
      id: 555,
      name: "Shehan",
      department: { name: "Department 01", color: "purple" },
      joiningDate: "15 Aug, 2024",
      annualLeave: 24,
      casualLeave: 12,
      sickLeave: 6,
      shortLeave: 12,
      halfDayLeave: 6,
    },
    {
      id: 510,
      name: "Charith",
      department: { name: "Department 02", color: "blue" },
      joiningDate: "15 Aug, 2024",
      annualLeave: 24,
      casualLeave: 12,
      sickLeave: 6,
      shortLeave: 12,
      halfDayLeave: 6,
    },
    {
      id: 559,
      name: "Karuna",
      department: { name: "Department 04", color: "green" },
      joiningDate: "15 Aug, 2024",
      annualLeave: 24,
      casualLeave: 12,
      sickLeave: 6,
      shortLeave: 12,
      halfDayLeave: 6,
    },
    {
      id: 558,
      name: "Osman",
      department: { name: "Department 03", color: "orange" },
      joiningDate: "15 Aug, 2024",
      annualLeave: 24,
      casualLeave: 12,
      sickLeave: 6,
      shortLeave: 12,
      halfDayLeave: 6,
    },
    {
      id: 559,
      name: "Osman",
      department: { name: "Department 03", color: "orange" },
      joiningDate: "15 Aug, 2024",
      annualLeave: 24,
      casualLeave: 12,
      sickLeave: 6,
      shortLeave: 12,
      halfDayLeave: 6,
    },
  ];


  return (
    <div className="mx-10 mt-5">
      <Navbar />

      <div className="flex justify-between mt-6">
        <div>
          <p className="text-[30px] font-semibold">Absence Report</p>
        </div>
        <div className="flex gap-6 items-center">
          <div>
            <div className="text-[#3D0B5E] text-[20px] font-bold">{currentDate}</div>
          </div>
          <div className="text-[20px] font-bold">{currentTime}</div>
        </div>
      </div>

      {/* second layer */}

      <div>
        <div className="flex justify-between items-center">
          <div className="flex gap-4 items-center mt-5">
            <div className="relative">
              <input
                className="border border-black rounded-xl p-2 pl-10 w-[325px]"
                placeholder="Search by ID or Department"
              />
              <CiSearch className="absolute top-1/2 left-3 transform -translate-y-1/2 text-gray-500" />
            </div>
            <div>
              <button className="p-2 border border-black rounded-[12px]">
                <div className="flex gap-3 items-center">
                  Filter <RiMenu5Fill />
                </div>
              </button>
            </div>
          </div>

          <div className="relative flex items-center gap-3 ">
            {/* <button
              className="p-3 border border-black rounded-[12px]"
              onClick={toggleDropdown}
            >
              <div className="flex gap-3 items-center">
                <div>All Department</div>
                <MdKeyboardArrowDown />
              </div>
            </button> */}
            {/* {isDropdownOpen && (
              <div className="absolute mt-2 w-48 bg-white border border-gray-300 rounded-lg shadow-lg">
                <ul>
                  <li className="p-2 hover:bg-gray-200 cursor-pointer">
                    Department 1
                  </li>
                  <li className="p-2 hover:bg-gray-200 cursor-pointer">
                    Department 2
                  </li>
                  <li className="p-2 hover:bg-gray-200 cursor-pointer">
                    Department 3
                  </li>
                </ul>
              </div>
            )} */}

            <select className="p-3 border border-black rounded-[12px]">
              <option value="Department">All Departments</option>
              <option value="Department_1">Department 1</option>
              <option value="Department_2">Department 2</option>
              <option value="Department_3">Department 3</option>
            </select>
        

          <div>
            <button className="p-3 border border-black rounded-[12px]">
              Export CSV
            </button>
          </div>
          </div>

        </div>
      </div>

      {/* third layer table*/}

      <div className="p-4">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-200">
            <tr>
              <th className="px-6 py-3">
                <input type="checkbox" />
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Date
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Employee Number
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Employee Name
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Department
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Leave Type
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Reason
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {paginatedData.map((row, index) => {
              const globalIndex = (currentPage - 1) * rowsPerPage + index;
              return (
                <tr key={index}>
                  <td className="px-6 py-4 whitespace-nowrap text-md text-gray-500">
                    <input
                      type="checkbox"
                      checked={selectedRows.includes(globalIndex)}
                      onChange={() => handleSelectRow(globalIndex)}
                    />
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-md text-gray-500">
                    {row.date}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-md text-gray-500">
                    {row.empNo}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-md text-gray-500">
                    {row.empName}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-md text-gray-500">
                    {row.dept}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-md text-gray-500">
                    {row.leaveType}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-md text-gray-500">
                    {row.reason}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <div className="flex justify-between items-center mt-4">
          <span className="text-sm text-gray-500">
            Showing {rowsPerPage * (currentPage - 1) + 1} to{" "}
            {Math.min(rowsPerPage * currentPage, data.length)} of {data.length}{" "}
            Employees
          </span>
          <div className="flex space-x-2">
            {[...Array(Math.ceil(data.length / rowsPerPage)).keys()].map(
              (page) => (
                <button
                  key={page}
                  onClick={() => handlePageChange(page + 1)}
                  className={`px-4 py-2 border ${
                    currentPage === page + 1 ? "bg-gray-300" : "bg-white"
                  }`}
                >
                  {page + 1}
                </button>
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Absence_Report;
