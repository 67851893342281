import React from "react";
import { Link } from "react-router-dom";
import Navbar  from "../navbar/navbar"
const PayrollNav = () => {
  return (
    <div className="bg-background overflow-y-auto h-screen">
      <Navbar/>
      <p className="text-[20px] font-header ml-6 mt-6 font-bold ">
        Payroll Management
      </p>
      <div className="flex ml-[-5%] gap-12 justify-center">
        <Link to="/monthend-payroll">
          <div className="bg-[#6A2E92] text-white rounded-[32px] h-[100px] w-[500px] p-4 shadow-md flex flex-col justify-between mt-[2.5%] ">
            <div className="flex justify-between items-center">
              <h2 className="text-[25px] font-bold mb-1 mt-1 ml-4 font-sans">
                Month-end Payroll
              </h2>
              <button className="bg-primary w-[151px] mt-2 text-white font-bold py-2 px-4 rounded-[20px]">
                Generate
              </button>
            </div>
            <div className="flex justify-between items-center ml-3">
              <div className="bg-white w-[37px] h-[13px] rounded-[20px]"></div>
            </div>
          </div>
        </Link>

        <Link to="/incentive">
          <div className="bg-[#6A2E92] text-white rounded-[32px] h-[100px] w-[400px] p-4 shadow-md flex flex-col justify-between mt-[2.5%] ">
            <div className="flex justify-between items-center">
              <h2 className="text-[25px] font-bold mb-1 mt-1 ml-4 font-sans">
                Incentive Payroll
              </h2>
              <button className="bg-primary w-[151px] mt-2 text-white font-bold py-2 px-4 rounded-[20px]">
                Generate
              </button>
            </div>
            <div className="flex justify-between items-center ml-3">
              <div className="bg-white w-[37px] h-[13px] rounded-[20px]"></div>
            </div>
          </div>
        </Link>

        <Link to="/generated-payroll">
          <div className="bg-[#6A2E92] text-white rounded-[32px] h-[100px] w-[500px] p-4 shadow-md flex flex-col justify-between mt-[2.5%] ">
            <div className="flex justify-between items-center">
              <h2 className="text-[25px] font-bold mb-1 mt-1 ml-4 font-sans">
                Generated Payroll
              </h2>
              <button className="bg-primary w-[151px] mt-2 text-white font-bold py-2 px-4 rounded-[20px]">
                Management
              </button>
            </div>
            <div className="flex justify-between items-center ml-3">
              <div className="bg-white w-[37px] h-[13px] rounded-[20px]"></div>
            </div>
          </div>
        </Link>
      </div>
      <div className="grid grid-cols-4 gap-12 mt-[2.5%] mx-6 font-sans justify-center mr-[8%]">
        <Link to="/allowances-and-deductions">
          <div className="bg-[#6A2E92] text-white rounded-[32px] h-[200px] w-[300px] p-4 shadow-md flex flex-col justify-between">
            <div>
              <h2 className="text-[25px] font-bold mb-1 mt-1 ml-4 font-sans">
                Payroll
              </h2>
              <h2 className="text-[25px] font-bold mb-1 ml-4 font-sans">
                Allowances
              </h2>
            </div>
            <div className="flex justify-between items-center">
              <button className="bg-gray-100 w-[151px] mt-2 text-[#6A2E92] font-bold py-2 px-4 rounded-[20px]">
                Click Here
              </button>
              <div className="bg-primary w-[13px] h-[37px] rounded-[20px]"></div>
            </div>
          </div>
        </Link>

        <Link to="/dedutions">
          <div className="bg-[#6A2E92] text-white rounded-[32px] h-[200px] w-[300px] p-4 shadow-md flex flex-col justify-between">
            <div>
              <h2 className="text-[25px] font-bold mb-1 mt-1 ml-4 font-sans">
                Payroll
              </h2>
              <h2 className="text-[25px] font-bold mb-1 ml-4 font-sans">
                Deductions
              </h2>
            </div>
            <div className="flex justify-between items-center">
              <button className="bg-gray-100 w-[151px] mt-2 text-[#6A2E92] font-bold py-2 px-4 rounded-[20px]">
                Click Here
              </button>
              <div className="bg-primary w-[13px] h-[37px] rounded-[20px]"></div>
            </div>
          </div>
        </Link>

        <Link to="/production_value">
          <div className="bg-[#6A2E92] text-white rounded-[32px] h-[200px] w-[300px] p-4 shadow-md flex flex-col justify-between">
            <div>
              <h2 className="text-[25px] font-bold mb-1 mt-1 ml-4 font-sans">
                Production
              </h2>
              <h2 className="text-[25px] font-bold mb-1 ml-4 font-sans">
                Value
              </h2>
            </div>
            <div className="flex justify-between items-center">
              <button className="bg-gray-100 w-[151px] mt-2 text-[#6A2E92] font-bold py-2 px-4 rounded-[20px]">
                Click Here
              </button>
              <div className="bg-primary w-[13px] h-[37px] rounded-[20px]"></div>
            </div>
          </div>
        </Link>

        <Link to="/section-incentive">
          <div className="bg-[#6A2E92] text-white rounded-[32px] h-[200px] w-[300px] p-4 shadow-md flex flex-col justify-between">
            <div>
              <h2 className="text-[25px] font-bold mb-1 mt-1 ml-4 font-sans">
                Section
              </h2>
              <h2 className="text-[25px] font-bold mb-1 ml-4 font-sans">
                Incentive
              </h2>
            </div>
            <div className="flex justify-between items-center">
              <button className="bg-gray-100 w-[151px] mt-2 text-[#6A2E92] font-bold py-2 px-4 rounded-[20px]">
                Click Here
              </button>
              <div className="bg-primary w-[13px] h-[37px] rounded-[20px]"></div>
            </div>
          </div>
        </Link>

        <Link to="/slab">
          <div className="bg-[#6A2E92] text-white rounded-[32px] h-[200px] w-[300px] p-4 shadow-md flex flex-col justify-between">
            <div>
              <h2 className="text-[25px] font-bold mb-1 mt-1 ml-4 font-sans">
                SLAB
              </h2>
            </div>
            <div className="flex justify-between items-center">
              <button className="bg-gray-100 w-[151px] mt-2 text-[#6A2E92] font-bold py-2 px-4 rounded-[20px]">
                Click Here
              </button>
              <div className="bg-primary w-[13px] h-[37px] rounded-[20px]"></div>
            </div>
          </div>
        </Link>

        <Link to="/special-incentive">
          <div className="bg-[#6A2E92] text-white rounded-[32px] h-[200px] w-[300px] p-4 shadow-md flex flex-col justify-between">
            <div>
              <h2 className="text-[25px] font-bold mb-1 mt-1 ml-4 font-sans">
                Special Incentive
              </h2>
            </div>
            <div className="flex justify-between items-center">
              <button className="bg-gray-100 w-[151px] mt-2 text-[#6A2E92] font-bold py-2 px-4 rounded-[20px]">
                Click Here
              </button>
              <div className="bg-primary w-[13px] h-[37px] rounded-[20px]"></div>
            </div>
          </div>
        </Link>

        <Link to="/overtime">
          <div className="bg-[#6A2E92] text-white rounded-[32px] h-[200px] w-[300px] p-4 shadow-md flex flex-col justify-between">
            <div>
              <h2 className="text-[25px] font-bold mb-1 mt-1 ml-4 font-sans">
                Overtime
              </h2>
            </div>
            <div className="flex justify-between items-center">
              <button className="bg-gray-100 w-[151px] mt-2 text-[#6A2E92] font-bold py-2 px-4 rounded-[20px]">
                Click Here
              </button>
              <div className="bg-primary w-[13px] h-[37px] rounded-[20px]"></div>
            </div>
          </div>
        </Link>

        <Link to="/leave-amount-management">
          <div className="bg-[#6A2E92] text-white rounded-[32px] h-[200px] w-[300px] p-4 shadow-md flex flex-col justify-between">
            <div>
              <h2 className="text-[25px] font-bold mb-1 mt-1 ml-4 font-sans">
                Leave Amount
              </h2>
              <h2 className="text-[25px] font-bold mb-1 ml-4 font-sans">
                Management
              </h2>
            </div>
            <div className="flex justify-between items-center">
              <button className="bg-gray-100 w-[151px] mt-2 text-[#6A2E92] font-bold py-2 px-4 rounded-[20px]">
                Click Here
              </button>
              <div className="bg-primary w-[13px] h-[37px] rounded-[20px]"></div>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default PayrollNav;
