import React, { useState } from 'react';


const  Time_And_Attendance_Table = () => {
  const sampleData = [
    {
      id: 555,
      name: 'Shehan',
      department: { name: 'Department 01', color: 'purple' },
      shift:"In",
      
      checkin: "9:12 AM",
      checkout: "9:12 AM",
    },
 
    {
        id: 555,
        name: 'Shehan',
        department: { name: 'Department 01', color: 'purple' },
        shift:"In",
        checkin: "9:12 AM",
        checkout: "9:12 AM",
      },
      {
        id: 555,
        name: 'Shehan',
        department: { name: 'Department 01', color: 'purple' },
        shift:"In",
        checkin: "9:12 AM",
        checkout: "9:12 AM",
      },
  ];

  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 5;

  // Calculate the total number of pages
  const totalPages = Math.ceil(sampleData.length / rowsPerPage);

  // Get the data for the current page
  const currentData = sampleData.slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage);

  // Change page
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className="p-4">
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-100">
          <tr >
            <th className="px-6 py-3 text-left text-md font-semibold text-gray-500 uppercase tracking-wider">ID</th>
            <th className="px-6 py-3 text-left text-md font-semibold text-gray-500 uppercase tracking-wider">Employee Name</th>
            <th className="px-6 py-3 text-left text-md font-semibold text-gray-500 uppercase tracking-wider">Department</th>
            <th className="px-6 py-3 text-left text-md font-semibold text-gray-500 uppercase tracking-wider">Shift</th>
            <th className="px-6 py-3 text-left text-md font-semibold text-gray-500 uppercase tracking-wider">Current Status</th>
            <th className="px-6 py-3 text-left text-md font-semibold text-gray-500 uppercase tracking-wider">Check In</th>
            <th className="px-6 py-3 text-left text-md font-semibold text-gray-500 uppercase tracking-wider">Check out</th>

          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {currentData.map((employee) => (
            <tr key={employee.id}>
              <td className="px-6 py-4 whitespace-nowrap text-md text-gray-500 text-md">{employee.id}</td>
              <td className="px-6 py-4 whitespace-nowrap text-md text-gray-900 text-md">{employee.name}</td>
              <td className="px-6 py-4 whitespace-nowrap text-md text-gray-900 text-md">
                <span className={`px-2 inline-flex text-md leading-5 font-semibold rounded-full bg-${employee.department.color}-100 text-${employee.department.color}-800`}>
                  {employee.department.name}
                </span>
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-md text-gray-500 text-md">{employee.shift}</td>
              <td className="px-6 py-4 whitespace-nowrap text-md text-gray-500 text-md">{employee.annualLeave}</td>
              <td className="px-6 py-4 whitespace-nowrap text-md text-gray-500 text-md">{employee.checkin}</td>
              <td className="px-6 py-4 whitespace-nowrap text-md text-gray-500 text-md">{employee.checkout}</td>

           
            </tr>
          ))}
        </tbody>
      </table>

      {/* Pagination */}
      <div className="flex justify-between items-center py-3">
        <div>
          Showing {currentData.length > 0 ? (currentPage - 1) * rowsPerPage + 1 : 0} to {currentPage * rowsPerPage > sampleData.length ? sampleData.length : currentPage * rowsPerPage} of {sampleData.length} employees
        </div>
        <div className="flex space-x-2">
          {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
            <button
              key={page}
              onClick={() => handlePageChange(page)}
              className={`px-3 py-1 border rounded-md ${currentPage === page ? 'bg-gray-300' : 'bg-white'}`}
            >
              {page}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Time_And_Attendance_Table;
