import React, { useState } from "react";
import { CiSearch } from "react-icons/ci";
import { FaBagShopping } from "react-icons/fa6";

const Edit_user_popup = () => {
  const [showResetPassword, setShowResetPassword] = useState(false);

  const handleResetPasswordClick = () => {
    setShowResetPassword(!showResetPassword);
  };

  return (
    <div className="flex justify-center items-center bg-gray-100 h-full">
      <div className="bg-white p-8 rounded-lg w-full">
        <h2 className="text-2xl font-semibold mb-6">
          Edit User Account
          <div className="flex gap-4 items-center mt-10">
            <div className="relative">
              <input
                className="border border-black rounded-xl p-2 pl-10 w-[425px]"
                placeholder="Search by ID or Department"
              />
              <CiSearch className="absolute top-1/2 left-3 transform -translate-y-1/2 text-gray-500" />
            </div>
          </div>
        </h2>
        <form>
          <div className="items-center gap-8">
            <div className="grid grid-cols-2 gap-y-[30px] gap-x-[60px] text-[20px]">
              <div>
                <label className="block text-gray-700">Employee ID</label>
                <input
                  type="text"
                  className="w-full border border-gray-300 p-2 rounded mt-2"
                  value=""
                  readOnly
                />
              </div>
              <div>
                <label className="block text-gray-700">Employee Name</label>
                <input
                  type="text"
                  className="w-full border border-gray-300 p-2 rounded mt-2"
                />
              </div>

              <div>
                <label className="block text-gray-700">Username</label>
                <input
                  type="text"
                  className="w-full border border-gray-300 p-2 rounded mt-2"
                />
              </div>
            </div>
          </div>

          <div className="mt-5">
            <button
              type="button"
              onClick={handleResetPasswordClick}
              className="text-[#8764A0] font-bold p-3 rounded-[40px] border"
            >
              Reset Password
            </button>
          </div>

          {showResetPassword && (
            <div className="mt-5">
              <label className="block text-gray-700">New Password</label>
              <input
                type="password"
                className="w-[50%] border border-gray-300 p-2 rounded mt-2"
              />
            </div>
          )}

          <div className="mt-1">
            <div className="flex items-center justify-between px-4 py-2">
              <div>{/* Left content */}</div>
              <div className="gap-4 flex">
                <button className="bg-[#8764A0] border border-black text-white px-4 py-2 rounded-xl">
                  Save
                </button>
                <button className="bg-gray-300 text-black px-4 py-2 rounded-xl">
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Edit_user_popup;
