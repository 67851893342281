import React, { useState, useEffect } from "react";
import { CiSearch } from "react-icons/ci"; // Importing CiSearch icon
import { RiMenu5Fill } from "react-icons/ri"; // Importing RiMenu5Fill icon
import Leave_process_popup from "../leave_request/leave_process_popup";
import { MdKeyboardArrowDown } from "react-icons/md";

const Leave_approve_table = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchInput, setSearchInput] = useState("");
  const [selectedDepartment, setSelectedDepartment] =
    useState("All Department");
  const [isOpen, setIsOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const rowsPerPage = 5;

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch(
        "https://backbritish.talentfort.live/v1/hris/leave/getleave"
      );
      const result = await response.json();
      setData(result); // Assuming result is the array of employees
      setFilteredData(result);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    handleFilter(); 
  }, [searchInput, selectedDepartment]);

  const handleFilter = () => {
    const newFilteredData = data.filter((employee) => {
      const matchesID = employee.employee_no.toString().includes(searchInput);
      const matchesDepartment =
        selectedDepartment === "All Department" ||
        employee.department === selectedDepartment;
      return matchesID && matchesDepartment;
    });
    setFilteredData(newFilteredData);
    setCurrentPage(1); // Reset to the first page after filtering
  };

  const handleDepartmentSelect = (department) => {
    setSelectedDepartment(department);
    handleFilter();
    setIsDropdownOpen(false);
  };

  const totalPages = Math.ceil(filteredData.length / rowsPerPage);

  const currentData = filteredData.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };

  const toggleForm = () => {
    setIsFormOpen(!isFormOpen);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleSearchChange = (e) => {
    setSearchInput(e.target.value);
  };

  const getLeaveTypeClass = (leaveType) => {
    switch (leaveType.toLowerCase()) {
      case "casual":
        return "bg-orange-400 text-white";
      case "anual":
        return "bg-green-100 text-green-800";
      case "special":
        return "bg-pink-500 text-white";
      case "medical":
        return "bg-pink-100 text-pink-800";
      default:
        return "bg-gray-100 text-gray-800"; // Default class for other types
    }
  };

  const getActionTypeClass = (actionType) => {
    switch (actionType) {
      case "APPROVED":
        return "bg-orange-400 text-white";
      case "NOT APPROVED":
        return "bg-green-100 text-green-800";
      default:
        return "bg-gray-100 text-gray-800"; // Default class for other types
    }
  };

  return (
    <div className="p-4">
      <div className="flex justify-between item-center mt-3">
        <div className="flex gap-4 items-center mt-5">
          <div className="relative">
            <input
              className="border border-black rounded-xl p-2 pl-10 w-[325px]"
              placeholder="Search by ID"
              value={searchInput}
              onChange={handleSearchChange}
            />
            <CiSearch className="absolute top-1/2 left-3 transform -translate-y-1/2 text-gray-500" />
          </div>
          <div>
            <button className="p-2 border border-black rounded-[12px]">
              <div className="flex gap-3 items-center">
                Filter <RiMenu5Fill />
              </div>
            </button>
          </div>
        </div>

        <div className="flex gap-4 items-center mt-5">
          <div className="relative">
            <button
              className="p-3 border border-black rounded-[12px]"
              onClick={toggleDropdown}
            >
              <div className="flex gap-3 items-center">
                <div>{selectedDepartment}</div>
                <MdKeyboardArrowDown />
              </div>
            </button>
            {isDropdownOpen && (
              <div className="absolute mt-2 w-48 bg-white border border-gray-300 rounded-lg shadow-lg">
                <ul>
                  <li
                    className="p-2 hover:bg-gray-200 cursor-pointer"
                    onClick={() => handleDepartmentSelect("All Department")}
                  >
                    All Department
                  </li>
                  <li
                    className="p-2 hover:bg-gray-200 cursor-pointer"
                    onClick={() => handleDepartmentSelect("Department 01")}
                  >
                    Department 01
                  </li>
                  <li
                    className="p-2 hover:bg-gray-200 cursor-pointer"
                    onClick={() => handleDepartmentSelect("Department 02")}
                  >
                    Department 02
                  </li>
                  <li
                    className="p-2 hover:bg-gray-200 cursor-pointer"
                    onClick={() => handleDepartmentSelect("Department 03")}
                  >
                    Department 03
                  </li>
                </ul>
              </div>
            )}
          </div>
          <div>
            <input type="date" className="border border-black rounded-xl p-2" />
          </div>
        </div>
      </div>

      <table className="min-w-full divide-y divide-gray-200 mt-12">
        <thead className="bg-gray-100">
          <tr>
            <th className="px-6 py-3 text-left text-md font-semibold text-gray-500 uppercase tracking-wider">
              ID
            </th>
            <th className="px-6 py-3 text-left text-md font-semibold text-gray-500 uppercase tracking-wider">
              Employee Name
            </th>
            <th className="px-6 py-3 text-left text-md font-semibold text-gray-500 uppercase tracking-wider">
              Department
            </th>
            <th className="px-6 py-3 text-left text-md font-semibold text-gray-500 uppercase tracking-wider">
              Date of Leave Applied
            </th>
            <th className="px-6 py-3 text-left text-md font-semibold text-gray-500 uppercase tracking-wider">
              Leave Category
            </th>
            <th className="px-6 py-3 text-left text-md font-semibold text-gray-500 uppercase tracking-wider">
              Leave Requested Date
            </th>
            <th className="px-6 py-3 text-left text-md font-semibold text-gray-500 uppercase tracking-wider">
              Leaves taken under each leave category
            </th>
            <th className="px-6 py-3 text-left text-md font-semibold text-gray-500 uppercase tracking-wider">
              Reason
            </th>
            <th className="px-6 py-3 text-left text-md font-semibold text-gray-500 uppercase tracking-wider">
              Actions
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {currentData.map((employee) => (
            <tr key={employee.id}>
              <td className="px-6 py-4 whitespace-nowrap text-md text-gray-500">
                {employee.employee_no}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-md text-gray-900">
                {employee.employee_fullname}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-md text-gray-900">
                <span className="px-2 inline-flex text-md leading-5 font-semibold rounded-full">
                  {employee.department}
                </span>
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-md text-gray-500">
                {employee.requesting_date}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-md text-gray-500">
                <span
                  className={`px-2 inline-flex text-md leading-5 font-semibold rounded-full ${getLeaveTypeClass(
                    employee.leave_type
                  )}`}
                >
                  {employee.leave_type}
                </span>
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-md text-gray-500">
                {employee.requested_date}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-md text-gray-500 font-semibold underline cursor-pointer">
                <button
                  className="text-black border-none"
                  onClick={togglePopup}
                >
                  Click
                </button>
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-md text-gray-500">
                {employee.reason}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-md text-gray-500">
                <span
                  className={`px-2 inline-flex text-md leading-5 font-semibold rounded-full ${getActionTypeClass(
                    employee.approved_status_1
                  )}`}
                >
                  {employee.approved_status_1}
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Pagination */}
      <div className="flex justify-between items-center py-3">
        <div>
          Showing{" "}
          {currentData.length > 0 ? (currentPage - 1) * rowsPerPage + 1 : 0} to{" "}
          {currentPage * rowsPerPage > filteredData.length
            ? filteredData.length
            : currentPage * rowsPerPage}{" "}
          of {filteredData.length} employees
        </div>
        <div className="flex space-x-2">
          {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
            <button
              key={page}
              onClick={() => handlePageChange(page)}
              className={`px-3 py-1 border rounded-md ${
                currentPage === page ? "bg-gray-300" : "bg-white"
              }`}
            >
              {page}
            </button>
          ))}
        </div>
      </div>

      {isOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-800 bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <Leave_process_popup togglePopup = {togglePopup}/>
            {/* <button
              className="mt-4 bg-red-500 text-white px-4 py-2 rounded"
              onClick={togglePopup}
            >
              Close
            </button> */}
          </div>
        </div>
      )}
    </div>
  );
};

export default Leave_approve_table; 
