import React, { useState, useEffect } from "react";
import Navbar from "../navbar/navbar";
import moment from "moment";
import { CiCirclePlus } from "react-icons/ci";
import Time_Management_Table from "./time_management_table";

const Time_management = () => {
  const currentDate = moment().format("MMMM Do YYYY");
  const [currentTime, setCurrentTime] = useState(moment().format("h:mm:ss a"));
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(moment().format("h:mm:ss a"));
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  const handleAddEmployee = (employee) => {
    setSelectedEmployees([...selectedEmployees, employee]);
  };

  const handleRemoveEmployee = (employeeId) => {
    setSelectedEmployees(
      selectedEmployees.filter((emp) => emp.id !== employeeId)
    );
  };

  const InputField = ({ label }) => (
    <div>
      <label className="block text-sm font-medium text-gray-700 mb-1">
        {label}
      </label>
      <input type="text" className="w-full p-2 border rounded" />
    </div>
  );

  const Popup = ({ closePopup }) => {
    return (
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center z-50">
        <div className="bg-white p-6 rounded-lg shadow-lg max-w-4xl w-full max-h-[90vh] overflow-y-auto">
          <div className="flex justify-between items-center mb-4">
            <h1 className="text-2xl font-bold">Create Timetable</h1>
            <button
              onClick={closePopup}
              className="text-gray-500 hover:text-gray-700"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>

          <form className="space-y-6">
            <div className="grid grid-cols-2 gap-6">
              <InputField label="Time Table Name" />
              <InputField label="Check-In" />
              <InputField label="Check-In Start Time" />
              <InputField label="Check-In End Time" />
              <InputField label="Check-Out" />
              <InputField label="Check-Out Start Time" />
              <InputField label="Check-Out End Time" />
              <InputField label="Grace Period Start Time" />
              <InputField label="Grace Period End Time" />
              <InputField label="Short Leave Start Time" />
              <InputField label="Short Leave End Time" />
              <InputField label="Half Day Start Time" />
              <InputField label="Half Day End Time" />
              <InputField label="Required Short Leave Hours" />
              <InputField label="Required Half Day Leave Hours" />
            </div>

            <div className="mt-8">
              <h2 className="text-lg font-semibold mb-4">
                Assign Employees to Timetable
              </h2>
              <div className="flex space-x-4">
                <div className="w-1/2">
                  <input
                    type="text"
                    placeholder="Search by Employee"
                    className="w-full p-2 border rounded"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                  <div className="mt-2 border rounded max-h-40 overflow-y-auto">
                    {/* Simulated employee list - replace with actual data */}
                    {["John Doe", "Jane Smith"].map((name, index) => (
                      <div
                        key={index}
                        className="flex items-center justify-between p-2 hover:bg-gray-100"
                      >
                        <span>{name}</span>
                        <button
                          type="button"
                          onClick={() => handleAddEmployee({ id: index, name })}
                          className="text-purple-600 hover:text-purple-800"
                        >
                          Add
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="w-1/2">
                  <h3 className="font-semibold mb-2">Selected Employees</h3>
                  <div className="border rounded max-h-40 overflow-y-auto">
                    {selectedEmployees.map((employee) => (
                      <div
                        key={employee.id}
                        className="flex items-center justify-between p-2 hover:bg-gray-100"
                      >
                        <span>{employee.name}</span>
                        <button
                          type="button"
                          onClick={() => handleRemoveEmployee(employee.id)}
                          className="text-red-600 hover:text-red-800"
                        >
                          Remove
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>

            <div className="flex justify-end space-x-4 mt-8">
              <button
                type="button"
                className="px-4 py-2 bg-gray-200 text-gray-800 rounded hover:bg-gray-300"
              >
                Reset
              </button>
              <button
                type="submit"
                className="px-4 py-2 bg-purple-600 text-white rounded hover:bg-purple-700"
              >
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  };

  return (
    <div className="mx-10 mt-5">
      <Navbar />

      <div className="flex justify-between mt-5">
        <div>
          <p className="text-[30px] font-semibold">Time Management</p>
        </div>
        <div className="flex gap-6 items-center">
          <div>
            <div className="text-[#3D0B5E] text-[20px] font-bold">
              {currentDate}
            </div>
          </div>
          <div className="text-[20px] font-bold">{currentTime}</div>
        </div>
      </div>

      <div className="mt-5">
        <p className="text-[20px]">
          Time & Attendance{" "}
          <span className="text-[#6A2E92]">Time and Attendance</span>
        </p>
      </div>

      <div className="mt-10">
        <button
          className="text-white bg-[#8764A0] p-2 rounded-lg text-[20px]"
          onClick={togglePopup}
        >
          <div className="flex gap-5 items-center">
            Create Timetable
            <CiCirclePlus />
          </div>
        </button>
      </div>

      <div>
        <Time_Management_Table />
      </div>

      {isPopupOpen && <Popup closePopup={togglePopup} />}
    </div>
  );
};

export default Time_management;
