import React, { useState, useEffect } from "react";
import Navbar from "../../navbar/navbar";
import { CiCirclePlus } from "react-icons/ci";
import { IoIosCloudUpload } from "react-icons/io";
import moment from "moment";
import FileUpload from "./upload_files";

const EmpOnboard = () => {
  const [nextOfKinSections, setNextOfKinSections] = useState([{}]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [employeeData, setEmployeeData] = useState({
    fullName: "",
    nameWithInitials: "",
    callingName: "",
    nic: "",
    dob: "",
    gender: "Male",
    maritalStatus: "Single",
    contactNumber: "",
    permanentAddress: "",
    temporaryAddress: "",
    emailAddress: "",
    dateOfAppointment: "",
    basicSalary: "",
    accountNumber: "",
    accountName: "",
    bankName: "",
    branchName: "",
    department: "",
    designation: "",
  });

  const currentDate = moment().format("MMMM Do YYYY");
  const [currentTime, setCurrentTime] = useState(moment().format("h:mm:ss a"));

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(moment().format("h:mm:ss a"));
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  const handleAddSection = () => {
    if (nextOfKinSections.length < 5) {
      setNextOfKinSections([...nextOfKinSections, {}]);
    }
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEmployeeData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(
        "https://backbritish.talentfort.live/v1/hris/employees/addemployee",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(employeeData),
        }
      );
      const result = await response.json();
      if (result.success) {
        const employeeNo = result.employee_no;
        // Trigger file upload
        handleFileUpload(employeeNo);
      }
    } catch (error) {
      console.error("Error submitting employee data:", error);
    }
  };

  const handleFileUpload = async (employeeNo) => {
    const formData = new FormData();
    uploadedFiles.forEach((file) => {
      formData.append("attachment", file);
    });
    formData.append("employee_no", employeeNo);

    try {
      const response = await fetch(
        "https://backbritish.talentfort.live/v1/hris/employees/uploadEmployeeFiles",
        {
          method: "POST",
          body: formData,
        }
      );
      const result = await response.json();
      console.log("File upload result:", result);
    } catch (error) {
      console.error("Error uploading files:", error);
    }
  };

  return (
    <div className="mx-10 mt-5">
      <div>
        <Navbar />
        <div className="mt-6 flex justify-between">
          <div>
            <p className="text-[30px] font-semibold">Employee Onboarding</p>
            <p>
              Employee Management , Employee Quick Onboard{" "}
              <span className="text-[#6A2E92]">Employee Onboarding</span>
            </p>
          </div>
          <div className="flex gap-6 items-center">
            <div>
              <div className="text-[#3D0B5E] text-[20px] font-bold">
                {currentDate}
              </div>
            </div>
            <div className="text-[20px] font-bold">{currentTime}</div>
          </div>
        </div>
      </div>
      <form onSubmit={handleSubmit} className="max-w-6xl p-8">
        <h1 className="text-[30px] font-bold mb-8">Personal Details</h1>
        <div className="grid grid-cols-3 gap-y-[30px] gap-x-[60px] text-[20px]">
          <div>
            <label className="block text-gray-700">Designation</label>
            <input
              type="text"
              name="designation"
              value={employeeData.designation}
              onChange={handleChange}
              className="w-full border border-gray-300 p-2 rounded mt-2"
              readOnly
            />
          </div>
          <div>
            <label className="block text-gray-700">Employee Name</label>
            <input
              type="text"
              name="fullName"
              value={employeeData.fullName}
              onChange={handleChange}
              className="w-full border border-gray-300 p-2 rounded mt-2"
            />
          </div>
          <div>
            <label className="block text-gray-700">Name with Initials</label>
            <input
              type="text"
              name="nameWithInitials"
              value={employeeData.nameWithInitials}
              onChange={handleChange}
              className="w-full border border-gray-300 p-2 rounded mt-2"
            />
          </div>
        </div>

        <div className="grid grid-cols-3 gap-y-[30px] gap-x-[60px] text-[20px] mt-10">
          <div>
            <label className="block text-gray-700">Calling Name</label>
            <input
              type="text"
              name="callingName"
              value={employeeData.callingName}
              onChange={handleChange}
              className="w-full border border-gray-300 p-2 rounded mt-2"
            />
          </div>
          <div>
            <label className="block text-gray-700">
              NIC (National Identity Card)
            </label>
            <input
              type="text"
              name="nic"
              value={employeeData.nic}
              onChange={handleChange}
              className="w-full border border-gray-300 p-2 rounded mt-2"
            />
          </div>
          <div>
            <label className="block text-gray-700">Date of Birth</label>
            <input
              type="date"
              name="dob"
              value={employeeData.dob}
              onChange={handleChange}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:ring-purple-500"
            />
          </div>
        </div>

        <div className="grid grid-cols-3 gap-y-[30px] gap-x-[60px] text-[20px] mt-10">
          <div>
            <label className="block text-gray-700">Gender</label>
            <select
              name="gender"
              value={employeeData.gender}
              onChange={handleChange}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:ring-purple-500"
            >
              <option>Male</option>
              <option>Female</option>
            </select>
          </div>
          <div>
            <label className="block text-gray-700">Marital Status</label>
            <select
              name="maritalStatus"
              value={employeeData.maritalStatus}
              onChange={handleChange}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:ring-purple-500"
            >
              <option>Married</option>
              <option>Single</option>
            </select>
          </div>
          <div>
            <label className="block text-gray-700">Contact Number</label>
            <input
              type="text"
              name="contactNumber"
              value={employeeData.contactNumber}
              onChange={handleChange}
              className="w-full border border-gray-300 p-2 rounded mt-2"
            />
          </div>
        </div>

        <div className="grid grid-cols-3 gap-y-[30px] gap-x-[60px] text-[20px] mt-10">
          <div>
            <label className="block text-gray-700">Permanent Address</label>
            <input
              type="text"
              name="permanentAddress"
              value={employeeData.permanentAddress}
              onChange={handleChange}
              className="w-full border border-gray-300 p-2 rounded mt-2"
            />
          </div>
          <div>
            <label className="block text-gray-700">Temporary Address</label>
            <input
              type="text"
              name="temporaryAddress"
              value={employeeData.temporaryAddress}
              onChange={handleChange}
              className="w-full border border-gray-300 p-2 rounded mt-2"
            />
          </div>
          <div>
            <label className="block text-gray-700">Email Address</label>
            <input
              type="email"
              name="emailAddress"
              value={employeeData.emailAddress}
              onChange={handleChange}
              className="w-full border border-gray-300 p-2 rounded mt-2"
            />
          </div>
        </div>

        {nextOfKinSections.map((_, index) => (
          <React.Fragment key={index}>
            <h1 className="text-[30px] font-bold col-span-3 mt-8">
              Next Of Kin {index + 1} Details
            </h1>
            <div className="grid grid-cols-1 gap-y-[30px] text-[20px]">
              <div>
                <label className="block text-gray-700">Relationship</label>
                <select className="w-full border border-gray-300 p-2 rounded mt-2">
                  <option value="relationship1">Test1</option>
                  <option value="relationship2">Test2</option>
                  <option value="relationship3">Test3</option>
                </select>
              </div>
              <div>
                <label className="block text-gray-700">Name</label>
                <input
                  type="text"
                  className="w-full border border-gray-300 p-2 rounded mt-2"
                />
              </div>
              <div>
                <label className="block text-gray-700">
                  NIC (National Identity Card)
                </label>
                <input
                  type="text"
                  className="w-full border border-gray-300 p-2 rounded mt-2"
                />
              </div>
              <div>
                <label className="block text-gray-700">
                  DoB (Date of Birth)
                </label>
                <input
                  type="date"
                  className="w-full border border-gray-300 p-2 rounded mt-2"
                />
              </div>
            </div>
          </React.Fragment>
        ))}

        {nextOfKinSections.length < 5 && (
          <div className="mt-8">
            <button
              type="button"
              onClick={handleAddSection}
              className="p-3 border border-[#8764A0] rounded-[25px] text-[#8764A0]"
            >
              <div className="flex gap-2 items-center">
                <div>Add Another</div>
                <CiCirclePlus />
              </div>
            </button>
          </div>
        )}

        <h1 className="text-[30px] font-bold mb-8 mt-10">Employment Details</h1>
        <div className="grid grid-cols-2 gap-y-[30px] gap-x-[60px] text-[20px]">
          <div>
            <label className="block text-gray-700">Designation</label>
            <input
              type="text"
              name="designation"
              value={employeeData.designation}
              onChange={handleChange}
              className="w-full border border-gray-300 p-2 rounded mt-2"
              readOnly
            />
          </div>
          <div>
            <label className="block text-gray-700">Department</label>
            <input
              type="text"
              name="department"
              value={employeeData.department}
              onChange={handleChange}
              className="w-full border border-gray-300 p-2 rounded mt-2"
            />
          </div>
          <div>
            <label className="block text-gray-700">Date of Appointment</label>
            <input
              type="date"
              name="dateOfAppointment"
              value={employeeData.dateOfAppointment}
              onChange={handleChange}
              className="w-full border border-gray-300 p-2 rounded mt-2"
            />
          </div>
          <div>
            <label className="block text-gray-700">Basic Salary</label>
            <input
              type="text"
              name="basicSalary"
              value={employeeData.basicSalary}
              onChange={handleChange}
              className="w-full border border-gray-300 p-2 rounded mt-2"
            />
          </div>
        </div>

        <h1 className="text-[30px] font-bold mb-8 mt-10">Bank Details</h1>
        <div className="grid grid-cols-2 gap-y-[30px] gap-x-[60px] text-[20px]">
          <div>
            <label className="block text-gray-700">Account Number</label>
            <input
              type="text"
              name="accountNumber"
              value={employeeData.accountNumber}
              onChange={handleChange}
              className="w-full border border-gray-300 p-2 rounded mt-2"
            />
          </div>
          <div>
            <label className="block text-gray-700">Account Name</label>
            <input
              type="text"
              name="accountName"
              value={employeeData.accountName}
              onChange={handleChange}
              className="w-full border border-gray-300 p-2 rounded mt-2"
            />
          </div>
          <div>
            <label className="block text-gray-700">Bank Name</label>
            <input
              type="text"
              name="bankName"
              value={employeeData.bankName}
              onChange={handleChange}
              className="w-full border border-gray-300 p-2 rounded mt-2"
            />
          </div>
          <div>
            <label className="block text-gray-700">Bank Branch</label>
            <input
              type="text"
              name="branchName"
              value={employeeData.branchName}
              onChange={handleChange}
              className="w-full border border-gray-300 p-2 rounded mt-2"
            />
          </div>
        </div>

        <h1 className="text-[30px] font-bold mb-8 mt-10">Personal Documents</h1>
        <button
          type="button"
          onClick={handleOpenModal}
          className="p-3 border border-[#8764A0] rounded-[25px] text-[#8764A0]"
        >
          <div className="flex gap-2 items-center">
            <div className="text-[15px] font-bold">Click Here to Upload</div>
            <IoIosCloudUpload />
          </div>
        </button>

        <div className="mt-5">
          <button
            type="submit"
            className="bg-[#8764A0] p-2 rounded-md text-white w-[150px]"
          >
            Submit
          </button>
        </div>
      </form>

      {isModalOpen && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-200 bg-opacity-80 z-50">
          <div className="bg-white rounded-lg p-8">
            <FileUpload
              setUploadedFiles={setUploadedFiles}
              uploadedFiles={uploadedFiles}
              onClose={handleCloseModal}
            />
            <button
              className="mt-4 bg-red-500 text-white py-2"
              onClick={handleCloseModal}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default EmpOnboard;
