import React, { useState, useEffect } from "react";
const LeaveDataTable = ({ searchInput }) => {
  const [leaveData, setLeaveData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 5;
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://backbritish.talentfort.live/v1/hris/leave/GetLeaveCountstoallemployee"
        );
        const data = await response.json();
        setLeaveData(data);
      } catch (error) {
        console.error("Error fetching leave data:", error);
      }
    };
    fetchData();
  }, []);
  //pagination
  const totalPages = Math.ceil(leaveData.length / rowsPerPage);
  // filteration
  const filteredData = leaveData.filter((leave) =>
    leave.employee_no.toLowerCase().includes(searchInput.toLowerCase())
  );
  //data filteration
  const currentData = filteredData.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className="p-4">
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-100">
          <tr>
            <th className="px-6 py-3 text-left text-md font-semibold text-gray-500 uppercase tracking-wider">
              Employee No
            </th>
            <th className="px-6 py-3 text-left text-md font-semibold text-gray-500 uppercase tracking-wider">
              Department
            </th>
            <th className="px-6 py-3 text-left text-md font-semibold text-gray-500 uppercase tracking-wider">
              Employee Name
            </th>
            <th className="px-6 py-3 text-left text-md font-semibold text-gray-500 uppercase tracking-wider">
              Date of Appointment
            </th>
            <th className="px-6 py-3 text-left text-md font-semibold text-gray-500 uppercase tracking-wider">
              Annual Leave
            </th>
            <th className="px-6 py-3 text-left text-md font-semibold text-gray-500 uppercase tracking-wider">
              Casual Leave
            </th>

            <th className="px-6 py-3 text-left text-md font-semibold text-gray-500 uppercase tracking-wider">
              Medical Leave
            </th>
            <th className="px-6 py-3 text-left text-md font-semibold text-gray-500 uppercase tracking-wider">
              Special Leave
            </th>

            <th className="px-6 py-3 text-left text-md font-semibold text-gray-500 uppercase tracking-wider">
              No Pay
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {currentData.map((leave) => (
            <tr key={leave.employee_no}>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {leave.employee_no}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {leave.department}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                {leave.employee_fullname}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {leave.date_of_appointment}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {leave.current_leave_counts.anualleavecount} /
                {leave.actual_leave_counts.anualleavecount}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {leave.current_leave_counts.anualleavecount} /
                {leave.actual_leave_counts.anualleavecount}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {leave.current_leave_counts.casualleavecount} /
                {leave.actual_leave_counts.casualleavecount}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {leave.current_leave_counts.medicalleavecount} /
                {leave.actual_leave_counts.medicalleavecount}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {leave.current_leave_counts.noPayLeaveCount}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {/* Pagination */}
      <div className="flex justify-between items-center py-3">
        <div>
          Showing{" "}
          {currentData.length > 0 ? (currentPage - 1) * rowsPerPage + 1 : 0} to{" "}
          {currentPage * rowsPerPage > leaveData.length
            ? leaveData.length
            : currentPage * rowsPerPage}{" "}
          of {leaveData.length} employees
        </div>
        <div className="flex space-x-2">
          {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
            <button
              key={page}
              onClick={() => handlePageChange(page)}
              className={`px-3 py-1 border rounded-md ${
                currentPage === page ? "bg-gray-300" : "bg-white"
              }`}
            >
              {page}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};
export default LeaveDataTable;
