import React from "react";
import { FaHome, FaUser, FaClock } from "react-icons/fa";
import { MdDashboard } from "react-icons/md";

const sidebarData = [
  {
    name: "Employee Management",
    icon: <MdDashboard />,
    subModules: [
      {
        name: "Employee Dashboard",
        url: "/emp-dashboard",
      },
      {
        name: "Employee Onboarding",
        url: "/emp-onboard",
      },
      {
        name: "View Employee Details",
        url: "/emp-details",
      },
      {
        name: "History Login Details (Management)",
        url: "/history-login-details",
      },
      {
        name: "History Logged Details",
        url: "/history-logged",
      },
      {
        name: "User Account Creation",
        url: "/create-user-account",
      },
    ],
  },

  {
    name: "Time & Attendance",
    icon: <FaClock />,
    subModules: [
      {
        name: "live Dashboard",
        url: "/time-attendance-dashboard",
      },
      {
        name: "Time Management",
        url: "/time-management",
      },
      {
        name: "Check-In Check-Out Reports",
        url: "/Checkin-checkout-Report",
      },
      {
        name: "Absence Report",
        url: "/absence-report",
      },

      {
        name: "Checkin-checkout Report",
        url: "/checkin-checkout-Report",
      },

      {
        name: "Departmental Comparison",
        url: "/department-comparison",
      },
    ],
  },
  {
    name: "Leave Management",
    icon: <FaUser />,
    subModules: [
      {
        name: "Leaves Dashboard",
        url: "/leave-info",
      },
      {
        name: "Employee Leave Management",
        url: "/leave-management",
      },
      {
        name: "Leave Approval process",
        url: "/leave-approve",
      },
      {
        name: "Date Restrictions",
        url: "/restricted-date",
      },
      {
        name: "Leave Request",
        url: "/leave-request",
      },

      {
        name: "HR Reports",
        url: "/hr-report",
      },
      {
        name: "Current Leave Balance Reports",
        url: "/leave-balance-report",
      },
    ],
  },

  {
    name: "Pay Roll",
    icon: <FaUser />,
    subModules: [
      {
        name: "Payroll Navigation",
        url: "/payroll",
      },
      
    ],
  },

  {
    name: "Settings",
    icon: <FaUser />,
    subModules: [
      {
        name: "User Permission",
        url: "/user-permission",
      },
    ],
  },

  {
    name: "Home",
    url: "/home",
    icon: <FaHome />,
  },
];

export default sidebarData;
