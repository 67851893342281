import React, { PureComponent } from "react";
import {
  BarChart,
  Bar,
  Rectangle,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const data = [
  { name: "No Pay Leave", value: 10, fill: "#FF00FF" },
  { name: "Annual Leave", value: 15, fill: "#FFFF99" },
  { name: "Casual Leave", value: 25, fill: "#99FF99" },
  { name: "Medical Leave", value: 12, fill: "#FF9933" },
  { name: "Special Leave", value: 18, fill: "#CC99FF" },
];

const Leave_bar_chart = () => {
  return (
    <ResponsiveContainer width="100%" height={400}>
      <BarChart
        layout="vertical"
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis type="number" />
        <YAxis type="category" dataKey="name" />
        <Tooltip />
        <Bar dataKey="value" />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default Leave_bar_chart;
