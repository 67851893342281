import React, { useState } from "react";
import { CiCirclePlus } from "react-icons/ci";

const LeaveRequestPopup = ({employeeNo, onClose}) => {
  const [isEmailSectionVisible, setIsEmailSectionVisible] = useState(false);
  const [isFormOpen, setIsFormOpen] = useState(true);

  const handleAddHeadClick = () => {
    setIsEmailSectionVisible(true);
  };

  const toggleForm = () => {
    setIsFormOpen(!isFormOpen);
  };

  if (!isFormOpen) {
    return null;
  }

  return (
    <div className="flex justify-center items-center bg-gray-100 h-full">
      <div className="bg-white p-8 rounded-lg w-full">
        <div className="flex justify-between items-center">
          <div>
            <h2 className="text-2xl font-semibold mb-6">Action</h2>
          </div>
        </div>

        <form>
          <div className="grid gap-5 items-center mt-5 grid-cols-1 md:grid-cols-3">
            <div className="flex flex-col gap-2">
              <label className="block text-sm font-medium text-gray-700">
                Employee ID: <span className="text-red-600"> *</span>
              </label>
              <input
                type="text"
                required
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:ring-purple-500"
              />
            </div>
            <div className="flex flex-col gap-2">
              <label className="block text-sm font-medium text-gray-700">
                Employee Name:
                <span className="text-red-600"> *</span>
              </label>
              <input
                type="text"
                required
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:ring-purple-500"
              />
            </div>
            <div className="flex flex-col gap-2">
              <label className="block text-sm font-medium text-gray-700">
                Department:
                <span className="text-red-600"> *</span>
              </label>
              <input
                type="text"
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:ring-purple-500"
              />
            </div>
          </div>

          <div className="grid gap-5 items-center mt-5 grid-cols-1 md:grid-cols-3">
            <div className="flex flex-col gap-2">
              <label className="block text-sm font-medium text-gray-700">
                Date of Leave Applied:
                <span className="text-red-600"> *</span>
              </label>
              <input
                type="text"
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:ring-purple-500"
              />
            </div>
            <div className="flex flex-col gap-2">
              <label className="block text-sm font-medium text-gray-700">
                Leave Category:
                <span className="text-red-600"> *</span>
              </label>
              <input
                type="text"
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:ring-purple-500"
              />
            </div>
            <div className="flex flex-col gap-2">
              <label className="block text-sm font-medium text-gray-700">
                Leave Requested Date:
                <span className="text-red-600"> *</span>
              </label>
              <input
                type="text"
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:ring-purple-500"
              />
            </div>
          </div>

          <div className="flex gap-5 items-center mt-10">
            <label>
              Reason:
              <span className="text-red-600"> *</span>
            </label>
            <input
              type="text"
              className="border border-gray-300 p-2 w-[50%] rounded-md shadow-sm focus:outline-none focus:ring focus:ring-purple-500"
            />
          </div>
          <div className="flex gap-6 mt-6 items-center justify-between">
            <div className="flex items-center gap-6">
              <div className="flex items-center gap-6">
                <label className="block text-sm font-medium text-gray-700">
                  Action:
                  <span className="text-red-600"> *</span>
                </label>
                <div className="flex items-center gap-2">
                  <label className="block text-sm font-medium text-gray-700">
                    Approve:
                  </label>
                  <input
                    type="checkbox"
                    className="w-5 h-5 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:ring-purple-500"
                  />
                </div>
                <div className="flex items-center gap-2">
                  <label className="block text-sm font-medium text-gray-700">
                    Communicate:
                  </label>
                  <input
                    type="checkbox"
                    className="w-5 h-5 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:ring-purple-500"
                  />
                </div>
                <div className="flex items-center gap-2">
                  <label className="block text-sm font-medium text-gray-700">
                    Reject:
                  </label>
                  <input
                    type="checkbox"
                    className="w-5 h-5 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:ring-purple-500"
                  />
                </div>
              </div>
            </div>
            <div className="flex">
              <button
                type="button"
                className="text-white bg-purple-700 font-[12px] py-2 px-4 border border-purple-600 rounded-md shadow-sm hover:bg-purple-600 hover:text-white transition duration-300"
                onClick={handleAddHeadClick}
              >
                <div className="flex items-center gap-3">
                  <div>Add head of the department</div>
                  <CiCirclePlus />
                </div>
              </button>
            </div>
          </div>
          {isEmailSectionVisible && (
            <div className="mt-6 flex justify-end items-center gap-8">
              <div className="flex gap-6 items-center">
                <label className="flex block text-sm font-medium text-gray-700">
                  Add Email:
                  <span className="text-red-600"> *</span>
                </label>
                <input
                  type="email"
                  className="mt-1 block w-[70%] px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:ring-purple-500"
                />
              </div>
              <div className="mt-4 flex justify-end">
                <button className="text-white bg-purple-600 font-[12px] py-2 px-4 border border-purple-600 rounded-md shadow-sm hover:bg-purple-600 hover:text-white transition duration-300">
                  Send
                </button>
              </div>
            </div>
          )}
          <div className="flex gap-5 items-center mt-5">
            <label>
              Remark:
              <span className="text-red-600"> *</span>
            </label>
            <input
              type="text"
              className="border border-gray-300 p-2 w-[50%] rounded-md shadow-sm focus:outline-none focus:ring focus:ring-purple-500"
            />
          </div>
          <div className="flex mt-5 items-center gap-5">
            <button className="text-white bg-purple-600 font-[12px] py-4 px-2 border border-purple-600 rounded-md shadow-sm hover:bg-purple-600 hover:text-white transition duration-300 gap-100">
              Request additional documents or information
            </button>
            <button
            type="button"
              className="absolute top-2 right-2 text-gray-500"
              onClick={onClose}
            >
              Close
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default LeaveRequestPopup;
