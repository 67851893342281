import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FaChevronDown, FaChevronRight } from "react-icons/fa";
import Sidebar_Logo from "../../assets/LOGO HRIS 6.png";
import {
  TbLayoutSidebarLeftExpand,
  TbLayoutSidebarRightExpand,
} from "react-icons/tb";
import sidebarData from "./sidebar_data";

const Sidebar = ({ isOpen, toggleSidebar }) => {
  const [expandedMenu, setExpandedMenu] = useState({});
  const location = useLocation();
  const navigate = useNavigate();

  const toggleSubMenu = (name) => {
    setExpandedMenu((prev) => ({ ...prev, [name]: !prev[name] }));
  };

  const isSelectedPath = (path) => {
    return (
      location.pathname === path || location.pathname.startsWith(`${path}/`)
    );
  };

  const renderSubModules = (subModules, parentPath = "") => {
    return (
      <ul className={`ml-4 ${!isOpen && "hidden"}`}>
        {subModules.map((subModule, index) => {
          const currentPath = `${parentPath}${subModule.url}`;
          const isSelected = isSelectedPath(currentPath);
          return (
            <li
              key={index}
              className={`flex items-center p-2 ${
                isSelected ? "font-bold text-white" : "text-gray-200"
              }`}
            >
              {isSelected && (
                <span className="mr-2 w-2 h-2 bg-white rounded-full"></span>
              )}
              <Link to={currentPath}>{subModule.name}</Link>
            </li>
          );
        })}
      </ul>
    );
  };

  return (
    <div
      className={`fixed top-0 left-0 h-screen bg-[#6A2E92] p-5 pt-8 transition-width duration-300 ${
        isOpen ? "w-64" : "w-20"
      }`}
    >
      <button
        onClick={toggleSidebar}
        className="absolute -right-3 top-9 w-10 h-10 rounded-full flex items-center justify-center bg-[#6A2E92]"
      >
        {isOpen ? (
          <TbLayoutSidebarRightExpand className="text-white" size={24} />
        ) : (
          <TbLayoutSidebarLeftExpand className="text-white" size={24} />
        )}
      </button>
      <div className="text-center text-white mb-6">
        <img
          src={Sidebar_Logo}
          alt="Logo"
          className={`mx-auto ${isOpen ? "w-24 h-24" : "w-10 h-10"}`}
        />
        {isOpen && <h1 className="text-xl font-semibold">British School</h1>}
      </div>
      <div className={`overflow-y-auto h-[calc(100vh-160px)]`}>
        <ul>
          {sidebarData.map((module, index) => {
            const isModuleSelected = isSelectedPath(module.url);
            const hasSubModules =
              module.subModules && module.subModules.length > 0;
            return (
              <li key={index} className="mt-1">
                <div
                  className={`flex items-center p-2 rounded-md cursor-pointer ${
                    isModuleSelected ? "bg-[#8764A0]" : "hover:bg-[#8764A0]"
                  } text-white`}
                  onClick={() =>
                    hasSubModules
                      ? toggleSubMenu(module.name)
                      : navigate(module.url)
                  }
                >
                  {module.icon && <span>{module.icon}</span>}
                  {isOpen && <span className="ml-2">{module.name}</span>}
                  {isOpen && hasSubModules && (
                    <span className="ml-auto">
                      {expandedMenu[module.name] ? (
                        <FaChevronDown />
                      ) : (
                        <FaChevronRight />
                      )}
                    </span>
                  )}
                </div>
                {expandedMenu[module.name] &&
                  hasSubModules &&
                  renderSubModules(module.subModules, module.url)}
              </li>
            );
          })}
        </ul>

        <div> 
          <button class="group flex items-center justify-start w-11 h-11 bg-[#6A2E92] rounded-full cursor-pointer relative overflow-hidden transition-all duration-200 shadow-lg hover:w-32 hover:rounded-lg active:translate-x-1 active:translate-y-1">
            <div class="flex items-center justify-center w-full transition-all duration-300 group-hover:justify-start group-hover:px-3">
              <svg class="w-4 h-4" viewBox="0 0 512 512" fill="white">
                <path d="M377.9 105.9L500.7 228.7c7.2 7.2 11.3 17.1 11.3 27.3s-4.1 20.1-11.3 27.3L377.9 406.1c-6.4 6.4-15 9.9-24 9.9c-18.7 0-33.9-15.2-33.9-33.9l0-62.1-128 0c-17.7 0-32-14.3-32-32l0-64c0-17.7 14.3-32 32-32l128 0 0-62.1c0-18.7 15.2-33.9 33.9-33.9c9 0 17.6 3.6 24 9.9zM160 96L96 96c-17.7 0-32 14.3-32 32l0 256c0 17.7 14.3 32 32 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32l-64 0c-53 0-96-43-96-96L0 128C0 75 43 32 96 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32z"></path>
              </svg>
            </div>
            <div class="absolute right-5 transform translate-x-full opacity-0 text-white text-lg font-semibold transition-all duration-300 group-hover:translate-x-0 group-hover:opacity-100">
              Logout
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
