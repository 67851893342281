/** @format */

import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { AiOutlineClear } from "react-icons/ai";

const PaymentDetails = () => {
  const { name, value, type } = useParams();
  const [uploadedFile, setUploadedFile] = useState(null);
  const [fileSelected, setFileSelected] = useState(false);
  const [allowanceData, setAllowanceData] = useState([]);
  const [showAlertMessage, setShowAlertMessage] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const { REACT_APP_API_ENDPOINT } = process.env;

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const allowedExtensions = /(\.csv)$/i;
    const fileNameMatchesValue = file.name === `${value}.csv`;

    if (!allowedExtensions.exec(file.name)) {
      setShowAlertMessage(true);
      setAlertMessage("Please upload only CSV files.");
    } else if (!fileNameMatchesValue) {
      setShowAlertMessage(true);
      setAlertMessage(
        `Please make sure the selected file's name is ${value}.csv`
      );
    } else {
      setUploadedFile(file);
      setFileSelected(true);
      setShowAlertMessage(false);
    }
  };

  const handleSubmit = async () => {
    try {
      const formData = new FormData();
      formData.append("file", uploadedFile);

      let endpoint = "";
      if (type === "Allowance") {
        formData.append("allowance_type", value);
        endpoint = `${REACT_APP_API_ENDPOINT}/v1/hris/payroll/uploadAllowance`;
      } else if (type === "Deduction") {
        formData.append("deductions_type", value);
        endpoint = `${REACT_APP_API_ENDPOINT}/v1/hris/payroll/uploaddeduction`;
      }

      const response = await fetch(endpoint, {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        console.log("File uploaded successfully");
        setUploadedFile(null);
        setFileSelected(false);
      } else {
        const errorData = await response.json();
        console.error("Failed to upload file:", errorData);
      }
    } catch (error) {
      console.error("Error occurred while uploading file:", error);
    }
  };

  const handleClearClick = async () => {
    let url = "";
    if (type === "Allowance") {
      url = `${REACT_APP_API_ENDPOINT}/v1/hris/payroll/delete-allowance-by-type?allowance_type=${value}`;
    } else if (type === "Deduction") {
      url = `${REACT_APP_API_ENDPOINT}/v1/hris/payroll/delete-deduction-by-type?deduction_type=${value}`;
    }

    const options = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await fetch(url, options);
      if (response.ok) {
        const data = await response.json();
        console.log("Clearance successful:", data);
        alert(`${type} cleared successfully!`);
      } else {
        const errorData = await response.json();
        console.error(`Failed to clear ${type.toLowerCase()}:`, errorData);
        alert(`Failed to clear ${type.toLowerCase()}`);
      }
    } catch (error) {
      console.error(`Error clearing ${type.toLowerCase()}:`, error);
      alert(`Error clearing ${type.toLowerCase()}`);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      let endpoint = "";
      if (type === "Allowance") {
        endpoint = `${REACT_APP_API_ENDPOINT}/v1/hris/payroll/get-allowances-by-type?allowance_type=${value}`;
      } else if (type === "Deduction") {
        endpoint = `${REACT_APP_API_ENDPOINT}/v1/hris/payroll/get-deductions-by-type?deductions_type=${value}`;
      }

      try {
        const response = await fetch(endpoint);

        if (response.ok) {
          const data = await response.json();
          setAllowanceData(data.data);
        } else {
          const errorData = await response.json();
          console.error("Failed to fetch data:", errorData);
        }
      } catch (error) {
        console.error("Error occurred while fetching data:", error);
      }
    };

    fetchData();
  }, [value, type]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  return (
    <div className="bg-background h-screen overflow-y-auto">
      <div>
        <p className="font-sans text-[#797C80] text-[32px] ml-6 mt-6 font-bold">
          {name}
        </p>
      </div>

      <div className="mt-6">
        <div>
          <div className="flex justify-between items-center">
            <div>
              <div>
                <div className="flex flex-col">
                  <select
                    id="branchSelect"
                    name="branch"
                    autoComplete="off"
                    className="w-[300px] ml-6 h-10 rounded-[73px] py-1.5 bg-[#6A2E92] placeholder-white sm:text-sm px-2 font-sans"
                  >
                    <option className="font-sans-bold font-bold" >Branch</option>
                    <option>Dep 01</option>
                    <option>Dep 02</option>
                    <option>Dep 03</option>
                  </select>
                </div>
              </div>
              <div className="mt-3">
                <div>
                  <div className="flex flex-col">
                    <select
                      id="departmentSelect"
                      name="department"
                      autoComplete="off"
                      className="w-[300px] ml-6 h-10 rounded-[73px] py-1.5 bg-[#6A2E92] placeholder-white sm:text-sm px-2 font-sans"
                    >
                      <option className="font-sans-bold text-white">Department</option>
                      <option>Training #</option>
                      <option>Training #</option>
                      <option>Training #</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="flex flex-col font-poppins font-bold ml-6 mt-4">
                <div className="relative">
                  <button className="absolute left-2 -translate-y-1/2 top-1/2 p-1">
                    <svg
                      className="w-5 h-5 text-gray-700"
                      aria-labelledby="search"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      height="16"
                      width="17"
                    >
                      <path
                        strokeLinejoin="round"
                        strokeLinecap="round"
                        strokeWidth="1.333"
                        stroke="currentColor"
                        d="M7.667 12.667A5.333 5.333 0 107.667 2a5.333 5.333 0 000 10.667zM14.334 14l-2.9-2.9"
                      ></path>
                    </svg>
                  </button>
                  <input
                    type="text"
                    value={searchTerm}
                    onChange={handleSearchChange}
                    placeholder="Search..."
                    className="input rounded-full w-[300px] bg-[#6A2E92] h-10 px-8 py-3 border-2 border-transparent focus:outline-none focus:border-blue-500 placeholder-gray-400"
                  />
                </div>
              </div>
            </div>
            <div className="bg-[#6A2E92] rounded-[22px] h-[189px] w-[430px] mr-[5%]">
              <div className="text-white font-bold ml-4 mt-4 text-[29px]">
                <p>Upload CSV</p>
                <p>File</p>
              </div>

              <div className="flex items-center justify-between">
                <label
                  htmlFor="fileInput"
                  className={`bg-primary mt-5 ml-4 p-2 rounded-[32px] text-white font-bold cursor-pointer ${
                    fileSelected ? "hidden" : ""
                  }`}
                >
                  Upload
                </label>
                <input
                  type="file"
                  id="fileInput"
                  style={{ display: "none" }}
                  onChange={handleFileUpload}
                />
                <button
                  type="button"
                  className={`bg-primary mt-5 ml-4  p-2 rounded-[32px] text-white cursor-pointer ${
                    !fileSelected ? "hidden" : ""
                  }`}
                  onClick={handleSubmit}
                >
                  Submit
                </button>
                {uploadedFile && (
                  <div className="flex items-center mt-2 ml-6  justify-end">
                    <div>{uploadedFile.name}</div>
                  </div>
                )}
                {showAlertMessage && (
                  <div className="text-red-600 text-sm2 mt-2 ml-5">
                    {alertMessage}
                  </div>
                )}
                <div className="mr-5">
                  <div className="bg-primary rounded-[32px] h-[37px] w-[13px]"></div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="flex justify-end mr-[5%] mt-5 font-bold mb-5 sm:text-l"
            onClick={handleClearClick}
          >
            <div className=" ml-4 p-2 rounded-[32px] text-white bg-[#6A2E92] cursor-pointer w-[168.46px] h-[45px] flex items-center justify-center gap-2">
              <AiOutlineClear className="w-[25px] h-[25px] " />
              <label htmlFor="fileInput cursor-pointer ">Clear All</label>
            </div>
          </div>
          <div>
            <div className="flex flex-col font-sans font-bold ml-6 mt-6">
              <div className="max-h-[500px] overflow-y-auto">
                <table className="table-auto border-none w-[95%]">
                  <thead className=" sticky top-0">
                    <tr className="bg-gray-300 ">
                      <th className="text-primary px-4 py-2">Emp NO</th>
                      <th className="text-primary px-4 py-2">Value</th>
                    </tr>
                  </thead>
                  <tbody className="">
                    {allowanceData
                      .filter((item) =>
                        item.employee_no
                          .toLowerCase()
                          .includes(searchTerm.toLowerCase())
                      )
                      .map((item) => (
                        <tr key={item._id}>
                          <td className="bg-white px-4 py-2 text-center">
                            {item.employee_no}
                          </td>
                          <td className="bg-white px-4 py-2 text-center">
                            {item.value}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentDetails;
