import React, { PureComponent } from "react";
import {
  BarChart,
  Bar,
  Rectangle,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const data = [
    { name: 'Present Workforce', value: 10, fill: '#FF00FF' },
    { name: 'Absent Workforce', value: 15, fill: '#FFFF99' },
    { name: 'Late arrivals', value: 25, fill: '#99FF99' },
    // { name: 'Casual Leave', value: 12, fill: '#FF9933' },
    // { name: 'Medical Leave', value: 18, fill: '#CC99FF' },
  ];


const Emp_barchart = () => {
  return (
      <ResponsiveContainer width="100%" height={400}>
      <BarChart
        layout="vertical"
        data={data}
        margin={{
          top: 5, right: 30, left: 20, bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis type="number" />
        <YAxis type="category" dataKey="name" />
        <Tooltip />
        <Bar dataKey="value" />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default Emp_barchart;
