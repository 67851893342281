import React , {useState, useEffect} from "react";
import Navbar from "../navbar/navbar";
import moment from "moment";
import { FaBagShopping } from "react-icons/fa6";
import { PiOfficeChairLight } from "react-icons/pi";
import { PiSealWarningLight } from "react-icons/pi";
import { CiClock2 } from "react-icons/ci";
import { TbBeach } from "react-icons/tb";
import { CiSearch } from "react-icons/ci";
import { RiMenu5Fill } from "react-icons/ri";
import { MdKeyboardArrowDown } from "react-icons/md";
import Time_And_Attendance_Table from "./time_and_attendance_table";

const Time_And_Attendance = () => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
      };
 
  const currentDate = moment().format("MMMM Do YYYY");
  const [currentTime, setCurrentTime] = useState(moment().format("h:mm:ss a"));

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(moment().format("h:mm:ss a"));
    }, 1000);
    return () => clearInterval(timer);
  }, []);
  return (
    <div className="mx-10 mt-5">
      <Navbar />
      <div className="flex justify-between mt-5 items-center">
        <div>
          <p className="text-[30px] font-semibold">
            Dashboard - Time and Attendance
          </p>
        </div>

        <div className="flex gap-6 items-center">
          <div>
            <div className="text-[#3D0B5E] text-[20px] font-bold">{currentDate}</div>
          </div>
          <div className="text-[20px] font-bold">{currentTime}</div>
        </div>
      </div>

      <div className="mt-10">
        {/* card layer */}
        <div className="mt-10">
          <div className="grid grid-cols-5 grid-flow-rows gap-5">
            {/* card 1 */}
            <div className="border border-black p-3 rounded-xl">
              <div className="flex justify-between items-center mt-5">
                <div>
                  <FaBagShopping className="w-8 h-8" />
                </div>

                <div className="">
                  <p className="font-semibold text-green-400">Per Annum</p>
                </div>
              </div>
              <p className="mt-5 font-semibold">Total Medical Leave Count</p>
              <p className="text-[30px] font-semibold">24</p>
            </div>
            {/* card 2 */}
            <div className="border border-black p-3 rounded-xl">
              <div className="flex justify-between items-center mt-5">
                <div>
                  <PiOfficeChairLight className="w-8 h-8" />
                </div>

                <div>
                  <p className="font-semibold text-green-400">Per Annum</p>
                </div>
              </div>
              <p className="mt-5 font-semibold">Total Medical Leave Count</p>
              <p className="text-[30px] font-semibold">24</p>
            </div>
            {/* card 3 */}
            <div className="border border-black p-3 rounded-xl">
              <div className="flex justify-between items-center mt-5">
                <div>
                  <PiSealWarningLight className="w-8 h-8" />
                </div>

                <div className="">
                  <p className="font-semibold text-green-400">Per Annum</p>
                </div>
              </div>
              <p className="mt-5 font-semibold">Total Annual Leave Count</p>
              <p className="text-[30px] font-semibold">24</p>
            </div>
            {/* card 4 */}
            <div className="border border-black p-3 rounded-xl">
              <div className="flex justify-between items-center mt-5">
                <div>
                  <CiClock2 className="w-8 h-8" />
                </div>

                <div className="">
                  <p className="font-semibold text-green-400">Per Annum</p>
                </div>
              </div>
              <p className="mt-5 font-semibold">Total Sick Leave Count</p>
              <p className="text-[30px] font-semibold">24</p>
            </div>

            {/* card 5 */}
            <div className="border border-black p-3 rounded-xl">
              <div className="flex justify-between items-center mt-5">
                <div>
                  <TbBeach className="w-8 h-8" />
                </div>

                <div className="">
                  <p className="font-semibold text-green-400">Per Annum</p>
                </div>
              </div>
              <p className="mt-5 font-semibold">Total Casual Leave Count</p>
              <p className="text-[30px] font-semibold">24</p>
            </div>
          </div>
        </div>

        <div className="mt-5">
          <div className="flex justify-between items-center">
            <div className="flex gap-4 items-center mt-5">
              <div className="relative">
                <input
                  className="border border-black rounded-xl p-2 pl-10 w-[325px]"
                  placeholder="Search by ID or Department"
                />
                <CiSearch className="absolute top-1/2 left-3 transform -translate-y-1/2 text-gray-500" />
              </div>
              <div>
                <button className="p-2 border border-black rounded-[12px]">
                  <div className="flex gap-3 items-center">
                    Filter <RiMenu5Fill />
                  </div>
                </button>
              </div>
            </div>

            <div className="flex gap-4 items-center">
              <div className="relative">
                <button
                  className="p-3 border border-black rounded-[12px]"
                  onClick={toggleDropdown}
                >
                  <div className="flex gap-3 items-center">
                    <div>All Department</div>
                    <MdKeyboardArrowDown />
                  </div>
                </button>
                {isDropdownOpen && (
                  <div className="absolute mt-2 w-48 bg-white border border-gray-300 rounded-lg shadow-lg">
                    <ul>
                      <li className="p-2 hover:bg-gray-200 cursor-pointer">
                        Department 1
                      </li>
                      <li className="p-2 hover:bg-gray-200 cursor-pointer">
                        Department 2
                      </li>
                      <li className="p-2 hover:bg-gray-200 cursor-pointer">
                        Department 3
                      </li>
                    </ul>
                  </div>
                )}
              </div>
              <div>
                <input
                  type="date"
                  className="border border-black rounded-xl p-2 "
                />
              </div>
            </div>


            
          </div>
        </div>

        <div className="mt-5">
            <Time_And_Attendance_Table/>
        </div>
      </div>
    </div>
  );
};

export default  Time_And_Attendance;
