import React, { useState,useEffect } from "react";
import Navbar from "./../../navbar/navbar";
import moment from "moment";
import { MdKeyboardArrowDown } from "react-icons/md";
// import Leave_Details from "../leave_details";
import { FaBagShopping } from "react-icons/fa6";

import Leave_approve_table from "./leave_approve_table";
import { CiSearch } from "react-icons/ci";
import { RiMenu5Fill } from "react-icons/ri";
const Leave_approve = () => {
 
  const currentDate = moment().format("MMMM Do YYYY");
  const [currentTime, setCurrentTime] = useState(moment().format("h:mm:ss a"));

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(moment().format("h:mm:ss a"));
    }, 1000);
    return () => clearInterval(timer);
  }, []);
  const toggleForm = () => {
    setIsFormOpen(!isFormOpen);
  };
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isFormOpen, setIsFormOpen] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <div className="mx-10 mt-5 overflow-y-auto ">
      <Navbar />

      <div className="flex justify-between mt-6">
        <p className="text-[30px] font-semibold">Leave Approve</p>
        <div className="flex gap-6 items-center">
          <div>
            <div className="text-[#3D0B5E] text-[20px] font-bold">{currentDate}</div>
          </div>
          <div className="text-[20px] font-bold">{currentTime}</div>
        </div>
      </div>


      

      {/* table */}
      <div>
        <Leave_approve_table />
      </div>
    </div>
  );
};

export default Leave_approve;
