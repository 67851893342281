import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useLocation,
} from "react-router-dom";
import Leave from "./components/hris/leave/leave/leave.jsx";
import Sidebar from "./components/sidebar/sidebar.jsx";
import Leave_Management from "./components/hris/leave/leave_managment/leave_management.jsx";
import Leave_Request from "./components/hris/leave/leave_request/leave_request.jsx";
import Leave_Approve from "./components/hris/leave/leave_approve/leave_approve.jsx";
import Leave_process_popup from "./components/hris/leave/leave_request/leave_process_popup.jsx";
import Leave_approve_popup from "./components/hris/leave/leave_approve/leave_approve_popup.jsx";
import Restricted_Date from "./components/hris/leave/restricted_date.jsx/restricted_date.jsx";
import Login from "./components/hris/login/login.jsx";
import Reports from "./components/hris/leave/reports/reports.jsx";
import HR_Reports from "./components/hris/leave/reports/hr_report/hr_report.jsx";
import Home from "./components/hris/employee/dashbaord/dashboard.jsx";
import Reset_Pw from "./components/hris/employee/dashbaord/reset_password.jsx";
import User_Profile from "./components/hris/employee/dashbaord/user_profile.jsx";
import Emp_Dashboard from "./components/hris/emp_management/dashboard/dashboard.jsx";
import Emp_details from "./components/hris/emp_management/view_emp_details/emp_details.jsx";
import Emp_Onboard from "./components/hris/emp_management/employee_quick_onboard/emp_onboard.jsx";
import Permission from "./components/hris/emp_management/permission/permission.jsx";
import History_login_details from "./components/hris/emp_management/history_login_details/history_login_details.jsx";
import User_account_creation from "./components/hris/emp_management/user_account_creation/user_account_creation.jsx";
import Time_Attendance_Dashboard from "./components/hris/time_and_attendance/time_and_attendance.jsx";
import Time_Management from "./components/hris/time_and_attendance/time_management.jsx";
import Absence_Report from "./components/hris/time_and_attendance/absence_report.jsx";
import Leave_Report from "./components/hris/leave/reports/leave_report/leave_report.jsx";
import Checkin_checkout_report from "./components/hris/time_and_attendance/checking_checkout_report.jsx";
import LeaveRequestPopup from "./components/hris/leave/leave_request/leave_request_popup.jsx";
import Hr_Report_Pdf from "./components/hris/leave/reports/hr_report/hr_report_pdf.jsx";
import Modal from "./components/hris/emp_management/employee_quick_onboard/testModal.jsx";
import Emp_details_pdf from "./components/hris/emp_management/view_emp_details/emp_details_pdf.jsx";
import Departmental_Comparison from "./components/hris/time_and_attendance/departmental_comparison.jsx";
import User_Permission from "./components/hris/settings/permission.jsx";
import Create_User_Permission from "./components/hris/settings/create_user_permission.jsx";
import History_Logged from "./components/hris/emp_management/history_logged_details/history_logged_details.jsx";
import Payments_Menu from "./components/hris/payrole/payroll_nav.jsx";
import Allowances from "./components/hris/payrole/month-end/allowances.jsx";
import Allowances_and_Deductions from "./components/hris/payrole/month-end/allowance_and_deduction_table.jsx";

const AppContent = ({ isSidebarOpen, toggleSidebar }) => {
  const location = useLocation();
  const showSidebar = location.pathname !== "/login";
  return (
    <div className="App flex">
      {showSidebar && (
        <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      )}
      <div
        className={`flex-grow p-5 transition-all duration-300 ${
          isSidebarOpen && showSidebar ? "ml-64" : "ml-20"
        }`}
      >
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/leave-info" element={<Leave />} />
          <Route path="/leave-management" element={<Leave_Management />} />
          <Route path="/leave-request" element={<Leave_Request />} />
          <Route path="/leave-approve" element={<Leave_Approve />} />
          <Route path="/leave-taken" element={<Leave_process_popup />} />
          <Route
            path="/leave-approve-popup"
            element={<Leave_approve_popup />}
          />
          <Route path="/restricted-date" element={<Restricted_Date />} />
          <Route path="/hr-report" element={<HR_Reports />} />
          <Route path="/leave-reports" element={<Reports />} />
          <Route path="/" element={<Navigate to="/login" />} />
          <Route path="/emp-details" element={<Emp_details />} />
          <Route path="/emp-pnboard" element={<Emp_Onboard />} />
          <Route path="/permission" element={<Permission />} />
          <Route path="/emp-details" element={<Emp_details />} />
          <Route path="/emp-onboard" element={<Emp_Onboard />} />
          <Route
            path="/history-Login-Details"
            element={<History_login_details />}
          />

          {/* employee portal */}
          <Route path="/home" element={<Home />} />
          <Route path="/reset-pw" element={<Reset_Pw />} />
          <Route path="/user-profile" element={<User_Profile />} />
          <Route path="/emp-dashboard" element={<Emp_Dashboard />} />
          <Route
            path="/create-user-account"
            element={<User_account_creation />}
          />
          <Route path="/leave-balance-report" element={<Leave_Report />} />

          {/* Time and Attendance */}
          <Route
            path="/time-attendance-dashboard"
            element={<Time_Attendance_Dashboard />}
          />

          <Route path="/time-management" element={<Time_Management />} />
          <Route path="/absence-report" element={<Absence_Report />} />
          <Route
            path="/Checkin-checkout-Report"
            element={<Checkin_checkout_report />}
          />
          <Route path="/Leave-Request-Popup" element={<LeaveRequestPopup />} />

          <Route path="/hr-report-pdf" element={<Hr_Report_Pdf />} />
          <Route path="/Modal-popup" element={<Modal />} />
          <Route path="/emp_details_pdf" element={<Emp_details_pdf />} />
          <Route
            path="/department-comparison"
            element={<Departmental_Comparison />}
          />
          <Route path="/user-permission" element={<User_Permission />} />
          <Route
            path="/create-user-permission"
            element={<Create_User_Permission />}
          />
          <Route path="/history-logged" element={<History_Logged />} />
          <Route path="/payroll" element={<Payments_Menu />} />
          <Route path="/allowances" element={<Allowances />} />
          <Route
            path="/allowances-and-deductions"
            element={<Allowances_and_Deductions />}
          />
        </Routes>
      </div>
    </div>
  );
};
function App() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  return (
    <Router>
      <AppContent isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
    </Router>
  );
}
export default App;
