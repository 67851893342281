import React, { useState, useEffect } from "react";
import Navbar from "../navbar/navbar";
import moment from "moment";
import { CiSearch } from "react-icons/ci";
import { IoCloudUploadOutline } from "react-icons/io5";
import { RiDownloadCloud2Line } from "react-icons/ri";

import Papa from "papaparse";
import { saveAs } from "file-saver";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import Checkin_checkout_report_table from "./checking_checkout_report_table";

const Checkin_checkout_report = () => {

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const currentDate = moment().format("MMMM Do YYYY");
  const [currentTime, setCurrentTime] = useState(moment().format("h:mm:ss a"));

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(moment().format("h:mm:ss a"));
    }, 1000);
    return () => clearInterval(timer);
  }, []);
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const [isFormOpen, setIsFormOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);

  // Change page
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 5;

  // const paginatedData = data.slice(
  //   (currentPage - 1) * rowsPerPage,
  //   currentPage * rowsPerPage
  // );

  const handleSelectRow = (index) => {
    setSelectedRows((prevSelectedRows) =>
      prevSelectedRows.includes(index)
        ? prevSelectedRows.filter((i) => i !== index)
        : [...prevSelectedRows, index]
    );
  };

  // Export to CSV function
  const exportToCSV = () => {
    const csvData = sampleData.map((employee) => ({
      ID: employee.id,
      FullName: employee.name,
      Department: employee.department.name,
      Name_With_Initials: employee.name,
      Calling_Name: employee.name,
      NIC: employee.name,
      DateofBirth: employee.name,
      DateofBirth: employee.DateofBirth,
      Gender: employee.name,
      Marital_Status: employee.name,
      Contact_number: employee.name,
      permanentAddress: employee.name,
      Temporary_Address: employee.name,
      Email_Address: employee.name,

      "Date of Appointment": employee.annualLeave,
    }));

    const csv = Papa.unparse(csvData);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    saveAs(blob, "employee_data.csv");
  };

  const sampleData = [
    {
      id: 555,
      name: "Shehan",
      department: { name: "Department 01", color: "purple" },
      joiningDate: "15 Aug, 2024",
      annualLeave: 24,
      casualLeave: 12,
      sickLeave: 6,
      shortLeave: 12,
      halfDayLeave: 6,
    },
    {
      id: 510,
      name: "Charith",
      department: { name: "Department 02", color: "blue" },
      joiningDate: "15 Aug, 2024",
      annualLeave: 24,
      casualLeave: 12,
      sickLeave: 6,
      shortLeave: 12,
      halfDayLeave: 6,
    },
    {
      id: 559,
      name: "Karuna",
      department: { name: "Department 04", color: "green" },
      joiningDate: "15 Aug, 2024",
      annualLeave: 24,
      casualLeave: 12,
      sickLeave: 6,
      shortLeave: 12,
      halfDayLeave: 6,
    },
    {
      id: 558,
      name: "Osman",
      department: { name: "Department 03", color: "orange" },
      joiningDate: "15 Aug, 2024",
      annualLeave: 24,
      casualLeave: 12,
      sickLeave: 6,
      shortLeave: 12,
      halfDayLeave: 6,
    },
    {
      id: 559,
      name: "Osman",
      department: { name: "Department 03", color: "orange" },
      joiningDate: "15 Aug, 2024",
      annualLeave: 24,
      casualLeave: 12,
      sickLeave: 6,
      shortLeave: 12,
      halfDayLeave: 6,
    },
  ];

  // Export to CSV function

  return (
    <div className="mx-10 mt-5">
      <Navbar />

      {/* second layer */}
      <div className="flex justify-between items-center mt-6">
        <div>
          <p className="text-[30px] font-semibold">
            Check in – Check out Report{" "}
          </p>
          <p className="text-[15px] font-semibold text-[#6A2E92]">
            Check in – Check out Report
          </p>
        </div>
        <div className="flex gap-6 items-center">
          <div>
            <div className="text-[#3D0B5E] text-[20px] font-bold">{currentDate}</div>
          </div>
          <div className="text-[20px] font-bold">{currentTime}</div>
        </div>
      </div>

      {/* third layer */}
      <div className="mt-5">
        <div className="flex justify-between items-center">
          <div className="flex gap-4 items-center">
            <div className="relative">
              <input
                className="border border-black rounded-xl p-3 pl-10 w-[200px] "
                placeholder="Search by ID, Name"
              />
              <CiSearch className="absolute top-1/2 left-3 transform -translate-y-1/2 text-gray-500" />
            </div>
          </div>

          <div className="relative flex items-center gap-3 ">
            <select className="p-3 border border-black rounded-[12px]">
              <option value="Department">All Departments</option>
              <option value="Department_1">Department 1</option>
              <option value="Department_2">Department 2</option>
              <option value="Department_3">Department 3</option>
            </select>
            {/* // Date selection section */}

            <div>
              <input
                type="date"
                className="border border-black rounded-xl p-2 "
              />
            </div>

            {/* // export csv button section */}
            <button
              className="p-3 border border-black rounded-[12px]"
              onClick={exportToCSV}
            >
              <div className="flex gap-3 items-center">
                <div>Export CSV</div>
                {/* <IoCloudUploadOutline /> */}
                <RiDownloadCloud2Line />
              </div>
            </button>
          </div>
        </div>
      </div>
      <div>

        <Checkin_checkout_report_table/>
      </div>
    </div>
  );
};

export default Checkin_checkout_report;
