import React, { useState, useEffect } from "react";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import Edit_user_popup from "./edit_user_popup";

const User_account_creation_table = () => {
  const [userData, setUserData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  const rowsPerPage = 5;

  // Fetch user data from the API
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await fetch(
          "https://backbritish.talentfort.live/v1/hris/user/getAllUsers"
        );
        if (response.ok) {
          const data = await response.json();
          setUserData(data);
        } else {
          console.error("Error fetching user data:", response.status);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchUserData();
  }, []);

  // Calculate the total number of pages
  const totalPages = Math.ceil(userData.length / rowsPerPage);

  // Get the data for the current page
  const currentData = userData.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  // Change page
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const toggleEditPopup = (user) => {
    setSelectedUser(user);
    setIsEditOpen(!isEditOpen);
  };

  return (
    <div className="p-4">
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-100">
          <tr>
            <th className="px-6 py-3 text-left text-md font-semibold text-gray-500 uppercase tracking-wider">
              ID
            </th>
            <th className="px-6 py-3 text-left text-md font-semibold text-gray-500 uppercase tracking-wider">
              Employee Name
            </th>
            <th className="px-6 py-3 text-left text-md font-semibold text-gray-500 uppercase tracking-wider">
              Username
            </th>
            <th className="px-6 py-3 text-left text-md font-semibold text-gray-500 uppercase tracking-wider">
              Role
            </th>
            <th className="px-6 py-3 text-left text-md font-semibold text-gray-500 uppercase tracking-wider">
              Status
            </th>
            <th className="px-6 py-3 text-left text-md font-semibold text-gray-500 uppercase tracking-wider">
              Actions
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {currentData.map((user) => (
            <tr key={user.id}>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-md">
                {user.id}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 text-md">
                {user.employee_fullname}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 text-md">
                {user.username}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 text-md">
                {user.role_name}
              </td>

              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 text-md">
                {user.employee_status}
              </td>
              <td className="flex items-center px-6 py-4 whitespace-nowrap text-sm font-medium gap-3 text-md">
                <button
                  className="text-blue-500 rounded-lg"
                  onClick={() => toggleEditPopup(user)}
                >
                  <FaEdit />
                </button>
                <FaTrashAlt className="text-red-500 cursor-pointer inline" />
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Pagination */}
      <div className="flex justify-between items-center py-3">
        <div>
          Showing{" "}
          {currentData.length > 0 ? (currentPage - 1) * rowsPerPage + 1 : 0} to{" "}
          {currentPage * rowsPerPage > userData.length
            ? userData.length
            : currentPage * rowsPerPage}{" "}
          of {userData.length} employees
        </div>
        <div className="flex space-x-2">
          {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
            <button
              key={page}
              onClick={() => handlePageChange(page)}
              className={`px-3 py-1 border rounded-md ${
                currentPage === page ? "bg-gray-300" : "bg-white"
              }`}
            >
              {page}
            </button>
          ))}
        </div>
      </div>

      {isEditOpen && selectedUser && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-800 bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <Edit_user_popup user={selectedUser} />
            <button
              className="mt-4 bg-red-500 text-white px-4 py-2 rounded"
              onClick={toggleEditPopup}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default User_account_creation_table;
