import React, { useState } from "react";
import { CiSearch } from "react-icons/ci";
import { RiMenu5Fill } from "react-icons/ri";
import { MdKeyboardArrowDown } from "react-icons/md";
import { MdDeleteOutline } from "react-icons/md";
import { AiTwotoneEdit } from "react-icons/ai";

const Time_Management_Table = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [employeeToDelete, setEmployeeToDelete] = useState(null);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const sampleData = [
    {
      id: 555,
      name: "Shehan",
      department: { name: "Department 01", color: "purple" },
      shift: "In",
      checkin: "9:12 AM",
      checkout: "9:12 AM",
    },
 
  ];

  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 5;

  // Calculate the total number of pages
  const totalPages = Math.ceil(sampleData.length / rowsPerPage);

  // Get the data for the current page
  const currentData = sampleData.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  // Change page
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleDeleteClick = () => {

    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
    
  };

  const handleConfirmDelete = () => {
    // Perform delete operation here
    console.log("Employee deleted:", employeeToDelete);
    handleClosePopup();
  };

  return (
    <div>
      <div className="mt-5">
        <div className="flex justify-between items-center">
          <div className="flex gap-4 items-center mt-5">
            <div className="relative">
              <input
                className="border border-black rounded-xl p-2 pl-10 w-[325px]"
                placeholder="Search by ID or Department"
              />
              <CiSearch className="absolute top-1/2 left-3 transform -translate-y-1/2 text-gray-500" />
            </div>
            <div>
              <button className="p-2 border border-black rounded-[12px]">
                <div className="flex gap-3 items-center">
                  Filter <RiMenu5Fill />
                </div>
              </button>
            </div>
          </div>

          <div className="flex gap-4 items-center">
            <div className="relative">
              <button
                className="p-3 border border-black rounded-[12px]"
                onClick={toggleDropdown}
              >
                <div className="flex gap-3 items-center">
                  <div>All Department</div>
                  <MdKeyboardArrowDown />
                </div>
              </button>
              {isDropdownOpen && (
                <div className="absolute mt-2 w-48 bg-white border border-gray-300 rounded-lg shadow-lg">
                  <ul>
                    <li className="p-2 hover:bg-gray-200 cursor-pointer">
                      Department 1
                    </li>
                    <li className="p-2 hover:bg-gray-200 cursor-pointer">
                      Department 2
                    </li>
                    <li className="p-2 hover:bg-gray-200 cursor-pointer">
                      Department 3
                    </li>
                  </ul>
                </div>
              )}
            </div>
            <div>
                <input
                  type="date"
                  className="border border-black rounded-xl p-2 "
                />
              </div>
          </div>

        </div>
      </div>
      {/* Table */}
      <div>
        <div className="p-4">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-100">
              <tr>
                <th className="px-6 py-3 text-left text-md font-semibold text-gray-500 uppercase tracking-wider">
                  Department
                </th>
                <th className="px-6 py-3 text-left text-md font-semibold text-gray-500 uppercase tracking-wider">
                  End Check-in Time
                </th>
                <th className="px-6 py-3 text-left text-md font-semibold text-gray-500 uppercase tracking-wider">
                  Start Check-out Time
                </th>
                <th className="px-6 py-3 text-left text-md font-semibold text-gray-500 uppercase tracking-wider">
                  End Check-out Time
                </th>
                <th className="px-6 py-3 text-left text-md font-semibold text-gray-500 uppercase tracking-wider">
                  End Check-out Time
                </th>
                <th className="px-6 py-3 text-left text-md font-semibold text-gray-500 uppercase tracking-wider">
                  Grace Period
                </th>
                <th className="px-6 py-3 text-left text-md font-semibold text-gray-500 uppercase tracking-wider">
                  Day - Specific Time Range
                </th>
                <th className="px-6 py-3 text-left text-md font-semibold text-gray-500 uppercase tracking-wider">
                  Action
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {currentData.map((employee) => (
                <tr key={employee.id}>
                  <td className="px-6 py-4 whitespace-nowrap text-md">
                    <span
                      className={`px-2 inline-flex leading-5 font-semibold rounded-full text-md`}
                    >
                      {employee.department.name}
                    </span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-md">
                    {employee.checkin}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-md">
                    {employee.checkout}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-md">
                    {employee.checkin}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-md">
                    {employee.checkout}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-md">
                    Grace Period
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    Specific Time Range
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                    <button
                      onClick={() => handleDeleteClick()}
                      className="text-red-600 hover:text-red-900"
                    >
                      <MdDeleteOutline />

                    </button>
                    <button className="text-indigo-600 hover:text-indigo-900 ml-4">
                    <AiTwotoneEdit />

                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {/* Pagination */}
          <div className="flex justify-between items-center py-3">
            <div>
              Showing{" "}
              {currentData.length > 0 ? (currentPage - 1) * rowsPerPage + 1 : 0}{" "}
              to{" "}
              {currentPage * rowsPerPage > sampleData.length
                ? sampleData.length
                : currentPage * rowsPerPage}{" "}
              of {sampleData.length} employees
            </div>
            <div className="flex space-x-2">
              {Array.from({ length: totalPages }, (_, i) => i + 1).map(
                (page) => (
                  <button
                    key={page}
                    onClick={() => handlePageChange(page)}
                    className={`px-3 py-1 border rounded-md ${
                      currentPage === page ? "bg-gray-300" : "bg-white"
                    }`}
                  >
                    {page}
                  </button>
                )
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Delete Confirmation Popup */}
      {isPopupOpen && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-5 rounded-md">
            <p className="text-lg font-bold flex justify-center">Are you sure you want to delete this employee?</p>
            <div className="flex justify-center mt-4">
              <button
                onClick={handleClosePopup}
                className="bg-gray-200 px-4 py-4 rounded-md mr-2"
              >
                Cancel
              </button>
              <button
                // onClick={handleConfirmDelete}
                className="bg-red-600 text-white px-4 py-2 rounded-md"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Time_Management_Table;
