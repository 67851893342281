import React, { useState, useEffect } from "react";
import Navbar from "../../navbar/navbar";
import moment from "moment";
import { CiSearch } from "react-icons/ci";
import { IoCloudUploadOutline } from "react-icons/io5";
import { RiDownloadCloud2Line } from "react-icons/ri";
import Papa from "papaparse";
import { saveAs } from "file-saver";
import { FaEdit, FaTrashAlt } from "react-icons/fa";

const Emp_details = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const currentDate = moment().format("MMMM Do YYYY");
  const [currentTime, setCurrentTime] = useState(moment().format("h:mm:ss a"));
  const [employeeData, setEmployeeData] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState("All");
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 5;

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(moment().format("h:mm:ss a"));
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    fetch(
      "https://backbritish.talentfort.live/v1/hris/employees/getemployeebasicdetails"
    )
      .then((response) => response.json())
      .then((data) => {
        setEmployeeData(data);
        const uniqueDepartments = [
          ...new Set(data.map((employee) => employee.department_name)),
        ];
        setDepartments(uniqueDepartments);
      })
      .catch((error) => console.error("Error fetching employee data:", error));
  }, []);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  // Export to CSV function
  const exportToCSV = () => {
    const csvData = employeeData.map((employee) => ({
      ID: employee.employee_no,
      FullName: employee.employee_fullname,
      Department: employee.department_name,
      Name_With_Initials: employee.employee_name_initial,
      Calling_Name: employee.employee_calling_name,
      NIC: employee.employee_nic,
      Date_of_Birth: employee.employee_dob,
      Gender: employee.employee_gender,
      Marital_Status: employee.employee_marital_status,
      Contact_Number: employee.employee_contact_no,
      Permanent_Address: employee.employee_permanent_address,
      Temporary_Address: employee.employee_temporary_address,
      Email_Address: employee.employee_email,
      Date_of_Appointment: employee.date_of_appointment,
      Status: employee.employee_active_status,
      Designation: employee.designation_name,
    }));

    const csv = Papa.unparse(csvData);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    saveAs(blob, "employee_data.csv");
  };

  const filteredData =
    selectedDepartment === "All"
      ? employeeData
      : employeeData.filter(
          (employee) => employee.department_name === selectedDepartment
        );

  // Calculate the total number of pages
  const totalPages = Math.ceil(filteredData.length / rowsPerPage);

  // Get the data for the current page
  const currentData = filteredData.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  // Change page
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className="mx-10 mt-5 mr-[40%] overflow-x-hidden">
      <Navbar />

      {/* second layer */}
      <div className="flex justify-between items-center mt-6">
        <div>
          <p className="text-[30px] font-semibold">View Employee Details </p>
          <p className="text-[15px] font-semibold text-[#6A2E92]">
            View Employee Details
          </p>
        </div>
        <div className="flex gap-6 items-center">
          <div>
            <div className="text-[#3D0B5E] text-[20px] font-bold">
              {currentDate}
            </div>
          </div>
          <div className="text-[20px] font-bold">{currentTime}</div>
        </div>
      </div>

      {/* third layer */}
      <div className="mt-5">
        <div className="flex justify-between items-center">
          <div className="flex gap-4 items-center">
            <div className="relative">
              <input
                className="border border-black rounded-xl p-3 pl-10 w-[200px] "
                placeholder="Search by ID, Name"
              />
              <CiSearch className="absolute top-1/2 left-3 transform -translate-y-1/2 text-gray-500" />
            </div>
          </div>

          <div className="relative flex items-center gap-3 ">
            <select
              className="p-3 border border-black rounded-[12px]"
              onChange={(e) => setSelectedDepartment(e.target.value)}
            >
              <option value="All">All Departments</option>
              {departments.map((dept, index) => (
                <option key={index} value={dept}>
                  {dept}
                </option>
              ))}
            </select>

            {/* // Active / Inactive button section */}
            <select className="p-3 border border-black rounded-[12px]">
              <option value="Active_or_Inactive">Active / Inactive</option>
              <option value="Active">Active</option>
              <option value="Inactive">Inactive</option>
            </select>

            {/* // export csv button section */}
            <button
              className="p-3 border border-black rounded-[12px]"
              onClick={exportToCSV}
            >
              <div className="flex gap-3 items-center">
                <div>Export CSV</div>
                <RiDownloadCloud2Line />
              </div>
            </button>
          </div>
        </div>
      </div>
      <div className="p-4">
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-100">
              <tr>
                <th className="px-6 py-3 text-left text-md font-semibold text-gray-500 uppercase tracking-wider">
                  ID
                </th>
                <th className="px-6 py-3 text-left text-md font-semibold text-gray-500 uppercase tracking-wider">
                  Full Name
                </th>
                <th className="px-6 py-3 text-left text-md font-semibold text-gray-500 uppercase tracking-wider">
                  Department
                </th>
                <th className="px-6 py-3 text-left text-md font-semibold text-gray-500 uppercase tracking-wider">
                  Name with Initials
                </th>
                <th className="px-6 py-3 text-left text-md font-semibold text-gray-500 uppercase tracking-wider">
                  Calling Name
                </th>
                <th className="px-6 py-3 text-left text-md font-semibold text-gray-500 uppercase tracking-wider">
                  NIC
                </th>
                <th className="px-6 py-3 text-left text-md font-semibold text-gray-500 uppercase tracking-wider">
                  Date of Birth
                </th>
                <th className="px-6 py-3 text-left text-md font-semibold text-gray-500 uppercase tracking-wider">
                  Gender
                </th>
                <th className="px-6 py-3 text-left text-md font-semibold text-gray-500 uppercase tracking-wider">
                  Marital Status
                </th>
                <th className="px-6 py-3 text-left text-md font-semibold text-gray-500 uppercase tracking-wider">
                  Contact Number
                </th>
                <th className="px-6 py-3 text-left text-md font-semibold text-gray-500 uppercase tracking-wider">
                  Permanent Address
                </th>
                <th className="px-6 py-3 text-left text-md font-semibold text-gray-500 uppercase tracking-wider">
                  Temporary Address
                </th>
                <th className="px-6 py-3 text-left text-md font-semibold text-gray-500 uppercase tracking-wider">
                  Email Address
                </th>
                <th className="px-6 py-3 text-left text-md font-semibold text-gray-500 uppercase tracking-wider">
                  Date of Appointment
                </th>
                <th className="px-6 py-3 text-left text-md font-semibold text-gray-500 uppercase tracking-wider">
                  Status
                </th>
                <th className="px-6 py-3 text-left text-md font-semibold text-gray-500 uppercase tracking-wider">
                  Designation
                </th>
                <th className="px-6 py-3 text-left text-md font-semibold text-gray-500 uppercase tracking-wider">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {currentData.map((employee) => (
                <tr key={employee.id}>
                  <td className="px-6 py-4 whitespace-nowrap text-md font-medium text-gray-900">
                    {employee.employee_no}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-md text-gray-500">
                    {employee.employee_fullname}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-md text-gray-500">
                    {employee.department_name}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-md text-gray-500">
                    {employee.employee_name_initial}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-md text-gray-500">
                    {employee.employee_calling_name}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-md text-gray-500">
                    {employee.employee_nic}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-md text-gray-500">
                    {employee.employee_dob}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-md text-gray-500">
                    {employee.employee_gender}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-md text-gray-500">
                    {employee.employee_marital_status}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-md text-gray-500">
                    {employee.employee_contact_no}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-md text-gray-500">
                    {employee.employee_permanent_address}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-md text-gray-500">
                    {employee.employee_temporary_address}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-md text-gray-500">
                    {employee.employee_email}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-md text-gray-500">
                    {employee.date_of_appointment}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-md text-gray-500">
                    {employee.employee_active_status}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-md text-gray-500">
                    {employee.designation_name}
                  </td>
                  <td className="px-8 py-4 whitespace-nowrap text-md font-medium">
                    <button
                      className="text-indigo-600 hover:text-indigo-900"
                      onClick={() =>
                        console.log(`Edit employee ${employee.id}`)
                      }
                    >
                      <FaEdit />
                    </button>
                  
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="mt-4 flex justify-end">
          {Array.from({ length: totalPages }, (_, index) => (
            <button
              key={index}
              className={`mx-1 px-3 py-1 rounded-lg ${
                currentPage === index + 1
                  ? "bg-blue-500 text-white"
                  : "bg-gray-200 text-gray-700"
              }`}
              onClick={() => handlePageChange(index + 1)}
            >
              {index + 1}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Emp_details;
