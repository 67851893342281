import React, { useEffect, useState } from "react";
import Navbar from "../navbar/navbar";
import moment from "moment";
import DepartmentalComparisonChart from "./departmental_comparison_chart";
import { FiSearch, FiCalendar, FiDownload } from "react-icons/fi";
const Departmental_Comparison = () => {
  const currentDate = moment().format("MMMM Do YYYY");
  const [currentTime, setCurrentTime] = useState(moment().format("h:mm:ss a"));

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(moment().format("h:mm:ss a"));
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  return (
    <div className="mx-10 mt-5">
      <Navbar />
      {/* second layer */}
      <div className="flex justify-between items-center mt-6">
        <div>
          <p className="text-[30px] font-semibold">
            Departmental Comparison Report
          </p>
        </div>
        <div className="flex gap-6 items-center">
          <div>
            <div className="text-[#3D0B5E] text-[20px] font-bold">
              {currentDate}
            </div>
          </div>
          <div className="text-[20px] font-bold">{currentTime}</div>
        </div>
      </div>
      <div style={{ height: "400px", width: "100%" }}>
        <DepartmentalComparisonChart />
      </div>

      <div>
        <div className="max-w-full mx-auto p-6 bg-white ">
          <div className="flex justify-between items-center mb-6">
            <div className="relative">
              <input
                type="text"
                placeholder="Search by ID, Name, Department..."
                className="pl-10 pr-4 py-2 border rounded-lg w-64"
              />
              <FiSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
            </div>
            <div className="flex items-center space-x-4">
              <select className="border rounded-lg px-4 py-2">
                <option>All Departments</option>
              </select>
              <div className="flex items-center border rounded-lg px-4 py-2">
                <FiCalendar className="mr-2" />
                <span>15 Aug, 2024</span>
              </div>
              <button className="flex items-center border-gray-200 border px-4 py-2 rounded-lg">
                <FiDownload className="mr-2" />
                Export CSV
              </button>
            </div>
          </div>

          <table className="w-full">
            <thead>
              <tr className="bg-gray-100">
                <th className="px-4 py-2 text-left">Department</th>
                <th className="px-4 py-2 text-left">Attendance Rate</th>
                <th className="px-4 py-2 text-left">Overtime</th>
                <th className="px-4 py-2 text-left">Absenteeism</th>
              </tr>
            </thead>
            <tbody>
              {/* Example rows */}
              <tr className="border-b">
                <td className="px-4 py-2">HR</td>
                <td className="px-4 py-2">95%</td>
                <td className="px-4 py-2">10 hrs</td>
                <td className="px-4 py-2">2%</td>
              </tr>
              <tr className="border-b">
                <td className="px-4 py-2">IT</td>
                <td className="px-4 py-2">98%</td>
                <td className="px-4 py-2">15 hrs</td>
                <td className="px-4 py-2">1%</td>
              </tr>
              {/* Add more rows as needed */}
            </tbody>
          </table>

          <div className="flex justify-between items-center mt-6">
            <span>Showing 1 to 5 of 07 Employees</span>
            <div className="flex space-x-2">
              <button className="px-3 py-1 border rounded">1</button>
              <button className="px-3 py-1 border rounded bg-purple-600 text-white">
                2
              </button>
              <span className="px-3 py-1">...</span>
              <button className="px-3 py-1 border rounded">07</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Departmental_Comparison;
