import React, { useState,useEffect } from "react";
import Navbar from "./../../../navbar/navbar";
import { MdKeyboardArrowDown } from "react-icons/md";

import { FaBagShopping } from "react-icons/fa6";

import { CiSearch } from "react-icons/ci";
import { RiMenu5Fill } from "react-icons/ri";
import moment from "moment";
import { TiTick } from "react-icons/ti";
import { IoMdCloseCircleOutline } from "react-icons/io";
import Current_Leave_Balance_table from "./current_Leave_Balance_table";

const Current_Leave_Balance_report = () => {
  const toggleForm = () => {
    setIsFormOpen(!isFormOpen);
  };
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isFormOpen, setIsFormOpen] = useState(false);
  
  const currentDate = moment().format("MMMM Do YYYY");
  const [currentTime, setCurrentTime] = useState(moment().format("h:mm:ss a"));

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(moment().format("h:mm:ss a"));
    }, 1000);
    return () => clearInterval(timer);
  }, []);
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <div className="mx-10 mt-5 overflow-y-auto ">
      <Navbar />

      <div className="mt-6 flex justify-between">
        <div>
          <p className="text-[30px] font-semibold">
            Current Leave Balance Report
          </p>
          <p>
            Leave , Current Leave Balance Report{" "}
            <span className="text-[#6A2E92]">Current Leave Balance Report</span>
          </p>
        </div>
        <div className="flex gap-6 items-center">
          <div>
            <div className="text-[#3D0B5E] text-[20px] font-bold">{currentDate}</div>
          </div>
          <div className="text-[20px] font-bold">{currentTime}</div>
        </div>
      </div>

      <div className="mt-8  ">
        <input
          className="border border-black rounded-xl p-2 pl-10 w-[325px]"
          placeholder="Search by ID, ame or Department"
        />
        <CiSearch className="absolute top-1/2 left-3 transform -translate-y-1/2 text-gray-500" />
      </div>


      <div className="grid grid-cols-3 gap-y-[30px] gap-x-[60px] text-[20px] mt-10">
          <div>
            <label className="block text-gray-700">Employee ID</label>
            <input
              type="text"
              className="w-full border border-gray-300 p-2 rounded mt-2"
              value="<fetched data>"
              readOnly
            />
          </div>
          <div>
            <label className="block text-gray-700">Employee Name</label>
            <input
              type="text"
              className="w-full border border-gray-300 p-2 rounded mt-2" value="<fetched data>"
            />
          </div>
          <div>
            <label className="block text-gray-700">Department</label>
            <input
              type="text"
              className="w-full border border-gray-300 p-2 rounded mt-2" value="<fetched data>"
            />
          </div>
         
        </div>

    
{/* // filter section  */}

        <div className="flex justify-between items-center mt-10">
          <div className="flex gap-4 items-center mt-5">
            <div className="relative">
              
              <button className="p-2 border border-black rounded-[12px]">
                <div className="flex gap-3 items-center">
                  Filter <RiMenu5Fill />
                </div>
              </button>
            </div>
          </div>

          <div className=" flex gap-6 relative">
            <button
              className="p-3 border border-black rounded-[12px]"
              onClick={toggleDropdown}
            >
              <div className="flex gap-3 items-center">
                <div>All Department</div>
                <MdKeyboardArrowDown />
              </div>
            </button>
            {isDropdownOpen && (
              <div className="absolute mt-2 w-48 bg-white border border-gray-300 rounded-lg shadow-lg">
                <ul>
                  <li className="p-2 hover:bg-gray-200 cursor-pointer">
                    Department 1
                  </li>
                  <li className="p-2 hover:bg-gray-200 cursor-pointer">
                    Department 2
                  </li>
                  <li className="p-2 hover:bg-gray-200 cursor-pointer">
                    Department 3
                  </li>
                </ul>
              </div>
            )}
            <div>
            <input
              type="date"
              className="border border-black rounded-xl p-2 "
            />
          </div>
          </div>
          
        </div>
<div className="mt-12 ">
      <div>{ <Current_Leave_Balance_table/>}</div>
      </div>
    </div>
  );
};

export default Current_Leave_Balance_report;
