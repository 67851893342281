import React, { useState, useEffect } from "react";
import Navbar from "./../../navbar/navbar";
import { MdKeyboardArrowDown } from "react-icons/md";
import Leave_Details from "../../emp_management/dashboard/leave_details";
import { FaBagShopping } from "react-icons/fa6";
import Leave_request_table from "./leave_request_table";
import { CiSearch } from "react-icons/ci";
import { RiMenu5Fill } from "react-icons/ri";
import moment from "moment";
import { TiTick } from "react-icons/ti";
import { IoMdCloseCircleOutline } from "react-icons/io";
const Leave_Request = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [leaveData, setLeaveData] = useState([]);
  const [selectedDepartment, setSelectedDepartment] =
    useState("All Departments");
  
  const [searchInput, setSearchInput] = useState("");
  const currentDate = moment().format("MMMM Do YYYY");
  const [currentTime, setCurrentTime] = useState(moment().format("h:mm:ss a"));

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(moment().format("h:mm:ss a"));
      
    }, 1000);
    return () => clearInterval(timer);
    
  }, []);



  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  const handleDepartmentSelect = (department) => {
    setSelectedDepartment(department);
    setIsDropdownOpen(false); // Close the dropdown after selection
  };
  // Fetch leave data from the backend
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://backbritish.talentfort.live/v1/hris/leave/getleave"
        );
        const data = await response.json();
        setLeaveData(data);
      } catch (error) {
        console.error("Error fetching leave data:", error);
      }
    };
    fetchData();
  }, []);
  // Calculate counts
  const receivedLeaveCount = leaveData.length;
  const acceptedLeaveCount = leaveData.filter(
    (leave) => leave.approved_status_1 === "APPROVED"
  ).length;
  const rejectedLeaveCount = leaveData.filter(
    (leave) => leave.approved_status_1 === "REJECTED"
  ).length;
  const handleSearchChange = (e) => {
    console.log({ handleSearchChange });
    setSearchInput(e.target.value);
  };
  return (
    <div className="mx-10 mt-5 overflow-y-auto">
      <Navbar />
      <div className="mt-6 flex justify-between">
        <div>
          <p className="text-[30px] font-semibold">Leave Request</p>
          <p>
            Leave , Leave Approve Process{" "}
            <span className="text-[#6A2E92]">Leave Request</span>
          </p>
        </div>
        <div className="flex gap-6 items-center">
          <div>
            <div className="text-[#3D0B5E] text-[20px] font-bold">{currentDate}</div>
          </div>
          <div className="text-[20px] font-bold">{currentTime}</div>
        </div>
      </div>
      <div className="flex justify-between items-center mt-5">
        <div className="grid grid-cols-3 grid-flow-row gap-8 h-[100px] w-[900px]">
          {/* card1 */}
          <div className="border border-black p-3 rounded-xl">
            <div className="flex justify-between items-center mt-5">
              <div>
                <FaBagShopping className="w-8 h-8" />
              </div>
              <div>
                <p className="font-semibold text-green-400">Count</p>
              </div>
            </div>
            <p className="mt-5 font-semibold">Received Leave Requests</p>
            <p className="text-[30px] font-semibold">{receivedLeaveCount}</p>
          </div>
          {/* card2 */}
          <div className="border border-black p-3 rounded-xl">
            <div className="flex justify-between items-center mt-5">
              <div>
                <TiTick className="w-8 h-8" />
              </div>
              <div className="">
                <p className="font-semibold text-green-400">Count</p>
              </div>
            </div>
            <p className="mt-5 font-semibold">Accepted Leave Requests</p>
            <p className="text-[30px] font-semibold">{acceptedLeaveCount}</p>
          </div>
          {/* card3 */}
          <div className="border border-black p-3 rounded-xl">
            <div className="flex justify-between items-center mt-5">
              <div>
                <IoMdCloseCircleOutline className="w-8 h-8" />
              </div>
              <div className="">
                <p className="font-semibold text-green-400">Count</p>
              </div>
            </div>
            <p className="mt-5 font-semibold">Rejected Leave Requests</p>
            <p className="text-[30px] font-semibold">{rejectedLeaveCount}</p>
          </div>
        </div>
        <div>
          <Leave_Details />
        </div>
      </div>
      <div className="mt-5">
        <div className="flex justify-between items-center">
          <div className="flex gap-4 items-center mt-5">
            <div className="relative">
              <input
                className="border border-black rounded-xl p-2 pl-10 w-[325px]"
                placeholder="Search by ID or Department"
                value={searchInput}
                onChange={handleSearchChange}
              />

              <CiSearch className="absolute top-1/2 left-3 transform -translate-y-1/2 text-gray-500" />
            </div>
            <div>
              <button className="p-2 border border-black rounded-[12px]">
                <div className="flex gap-3 items-center">
                  Filter <RiMenu5Fill />
                </div>
              </button>
            </div>
          </div>
          <div className="relative">
            <button
              className="p-3 border border-black rounded-[12px]"
              onClick={toggleDropdown}
            >
              <div className="flex gap-3 items-center">
                <div>{selectedDepartment}</div>
                <MdKeyboardArrowDown />
              </div>
            </button>
            {isDropdownOpen && (
              <div className="absolute mt-2 w-48 bg-white border border-gray-300 rounded-lg shadow-lg">
                <ul>
                  <li
                    className="p-2 hover:bg-gray-200 cursor-pointer"
                    onClick={() => handleDepartmentSelect("Department 1")}
                  >
                    Department 1
                  </li>
                  <li
                    className="p-2 hover:bg-gray-200 cursor-pointer"
                    onClick={() => handleDepartmentSelect("Department 2")}
                  >
                    Department 2
                  </li>
                  <li
                    className="p-2 hover:bg-gray-200 cursor-pointer"
                    onClick={() => handleDepartmentSelect("Department 3")}
                  >
                    Department 3
                  </li>
                  <li
                    className="p-2 hover:bg-gray-200 cursor-pointer"
                    onClick={() => handleDepartmentSelect("All Departments")}
                  >
                    All Departments
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
      <div>
        <Leave_request_table searchInput={searchInput} />
      </div>
    </div>
  );
};
export default Leave_Request;
